import React from "react";
import Layout from "../../Modules/LayoutModule/Components/Layout";
import NewsLetterTable from "../../Modules/NewsLetterModule/Component/NewsLetterTable/NewsLetterTable";

function NewsLetterPage() {
  return (
    <Layout>
      <NewsLetterTable />
    </Layout>
  );
}

export default NewsLetterPage;
