import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "../../../Utils/Helper";
import { SUPPLIER_PROFIE_RESPONSE_TYPE } from "../Types/Response";

export const GET_SUPPLIERPROFILE = (id: string) => [
  "GigProposalSupplierprofile",
  id,
];

const SupplierProfile = async (
  id: string
): Promise<AxiosResponse<SUPPLIER_PROFIE_RESPONSE_TYPE>> => {
  return fetcher({
    url: `/admin/gigs/proposals/${id}`,
    method: "GET",
  });
};

function useSupplierProfileQuery(id: any) {
  return useQuery(GET_SUPPLIERPROFILE(id), () => SupplierProfile(id));
}

export default useSupplierProfileQuery;
