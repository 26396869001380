import { fetcher } from "../../../Utils/Helper";
import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { CLIENT_STATUS_REQUEST_TYPES } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";

const clientStatus = async (
  data: CLIENT_STATUS_REQUEST_TYPES
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: `/admin/client/${data.id}`,
    method: "PATCH",
    data: data,
  });
};
function useClientStatusMutation() {
  return useMutation(clientStatus, {
    onSuccess: (response) => {
      if (response) {
        queryClient.refetchQueries();
      }
    },
  });
}
export default useClientStatusMutation;
