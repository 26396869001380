import { Box, Typography, Button, Divider } from "@mui/material";
import { Rating } from "react-simple-star-rating";
import BackButton from "../../../../Component/BackButton/BackButton";
import Layout from "../../../LayoutModule/Components/Layout";
import { useParams } from "react-router-dom";
import useClientGigDescriptionQuery from "../../Hooks/useClientGigDescriptionQuery";
import useDeleteGigMutation from "../../../GigModule/Hooks/useDeleteGigMutation";

function ClientGigsDeatils() {
  const { id: gigId } = useParams();
  const { data } = useClientGigDescriptionQuery(gigId);
  const deleteGig = useDeleteGigMutation();

  const gigDescription = data?.data?.data;
  const toDataURL = async (url: any) => {
    return await fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return window.URL.createObjectURL(blob);
      });
  };
  const downloadAttachment = async (url: any) => {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = url.replace(/^.*[\\/]/, "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const downloadAllAttachments = () => {
    if (gigDescription?.attachments && gigDescription?.attachments.length > 0) {
      gigDescription?.attachments.forEach((attachment) => {
        downloadAttachment(attachment);
      });
    } else {
      console.warn("No attachments found.");
    }
  };
  return (
    <Layout>
      <Box position="relative">
        <BackButton path={-1} />
        <Box
          p={1}
          display="flex"
          alignItems="center"
          width={"100%"}
          justifyContent="space-between"
        >
          <Typography
            variant="h4"
            fontWeight={600}
            py={2}
            color={"black"}
            textTransform={"capitalize"}
          >
            {gigDescription?.title}
          </Typography>
          <Button
            variant="contained"
            size="medium"
            color="error"
            onClick={() => {
              deleteGig?.mutate(gigDescription?._id);
            }}
          >
            Delete Gig
          </Button>
        </Box>
        <Box
          px={1}
          display="flex"
          alignItems="center"
          width={"100%"}
          justifyContent="space-between"
        >
          <Typography variant="h5" sx={{ color: "text.grey" }} fontWeight={500}>
            More About This Gigs
          </Typography>
          {gigDescription?.attachments?.length === 0 ? null : (
            <Button
              variant="outlined"
              size="medium"
              onClick={downloadAllAttachments}
            >
              Download Attachments
            </Button>
          )}
        </Box>
        <Typography
          sx={{ color: "text.grey" }}
          fontSize={15}
          fontWeight={500}
          p={1}
        >
          {gigDescription?.description}
        </Typography>

        <Box p={2}>
          <Typography variant="h5" pb={0.5} fontWeight={500} color={"black"}>
            Supplier's Reviews
          </Typography>
          <Box
            bgcolor={"common.black"}
            height={"2px"}
            width={"100%"}
            px={1}
          ></Box>
        </Box>
        <Box>
          <Box
            width={"100%"}
            px={3}
            display="flex"
            alignContent="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6" fontWeight={600} color={"black"}>
              Lorem, ipsum.
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "text.lightgrey" }}
              fontSize={13}
            >
              22/09/2022 01:23 PM
            </Typography>
          </Box>
          <Rating
            initialValue={5}
            size={18}
            style={{ marginLeft: "22px", marginTop: "2px" }}
          />
          <Box px={3}>
            <Typography
              variant="body1"
              sx={{ color: "text.grey" }}
              fontSize={14}
              pb={2}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum
              nulla aliquid omnis at eveniet veritatis perspiciatis, fugiat
              reprehenderit optio nam non dicta modi corporis atque molestiae.
              Officia perferendis ad alias? Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Maxime ipsum ipsam rem illo earum
              harum eaque. Veritatis reprehenderit veniam cum. Tempora ad
              placeat eaque nostrum?
            </Typography>
            <Divider />
          </Box>
        </Box>
        <Box p={2}>
          <Typography variant="h5" pb={0.5} fontWeight={500} color={"black"}>
            Client's Reviews
          </Typography>
          <Box
            bgcolor={"common.black"}
            height={"2px"}
            width={"100%"}
            px={1}
          ></Box>
        </Box>
        <Box>
          <Box
            width={"100%"}
            px={3}
            display="flex"
            alignContent="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6" fontWeight={600} color={"black"}>
              Lorem, ipsum.
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "text.lightgrey" }}
              fontSize={13}
            >
              22/09/2022 01:23 PM
            </Typography>
          </Box>
          <Rating
            initialValue={5}
            size={18}
            style={{ marginLeft: "22px", marginTop: "2px" }}
          />
          <Box px={3}>
            <Typography
              variant="body1"
              sx={{ color: "text.grey" }}
              fontSize={14}
              pb={2}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum
              nulla aliquid omnis at eveniet veritatis perspiciatis, fugiat
              reprehenderit optio nam non dicta modi corporis atque molestiae.
              Officia perferendis ad alias? Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Maxime ipsum ipsam rem illo earum
              harum eaque. Veritatis reprehenderit veniam cum. Tempora ad
              placeat eaque nostrum?
            </Typography>
            <Divider />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default ClientGigsDeatils;
