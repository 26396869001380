import { useState } from "react";
import ClientManagemnetTable from "../ClientManagementTable/ClientManagemnetTable";
import useClientQuery from "../../Hooks/useClientQuery";
import { useDebounce } from "use-debounce";
import { useParams, useSearchParams } from "react-router-dom";

function ClientManagement() {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [search] = useDebounce(searchQuery, 1000);
  // const { filterType, status, value, startDate, endDate }: any = useParams();
  // console.log(
  //   "clientmanagement",
  //   filterType,
  //   status,
  //   value,
  //   startDate,
  //   endDate
  // );
  const [searchParam, setSearchParams] = useSearchParams();
  const filterType: string | any = searchParam.get("filterType");
  const status: string | any = searchParam.get("status");
  const value: string | any = searchParam.get("value");
  const startDate: string | any = searchParam.get("startDate");
  const endDate: string | any = searchParam.get("endDate");

  // console.log("Fikehvbhvh", filterType, status, value, startDate, endDate);

  const { data } = useClientQuery(
    page,
    search,
    filterType,
    status,
    value,
    startDate,
    endDate
  );
  const ClientQuery = useClientQuery(
    page,
    search,
    filterType,
    status,
    value,
    startDate,
    endDate
  );
  const { clients, totalPages, currentPage } = data?.data?.data ?? {};

  // console.log("bhaclhbdachvvd", filterType)
  return (
    <>
      <ClientManagemnetTable
        page={page}
        currentPage={currentPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        clients={clients}
        setPage={setPage}
        totalPages={totalPages}
        ClientQuery={ClientQuery}
      />
    </>
  );
}

export default ClientManagement;
