import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "../../../Utils/Helper";
import { CLIENT_GIGDETAILS_RESPONSE_TYPES } from "../Types/ResponseTypes";

export const GET_CLIENTGIG_LIST = (
  userId: string | any,
  page: number | undefined,
  userFilter: string | undefined,
  search: string
) => ["clientGig", userId, page, userFilter, search];

const ClientGigList = async (
  userId: string | any,
  page: number | undefined,
  userFilter: string | undefined,
  search: string
): Promise<AxiosResponse<CLIENT_GIGDETAILS_RESPONSE_TYPES>> => {
  return fetcher({
    url: `/admin/gigs?page=${page}&userId=${userId}&userFilter=${userFilter}&search=${search}&limit=10`,
    method: "GET",
  });
};

function useClientGigsDeatilsQuery(
  userId: string | undefined,
  page: number | undefined,
  userFilter: string | undefined,
  search: string
) {
  return useQuery(GET_CLIENTGIG_LIST(userId, page, userFilter, search), () =>
    ClientGigList(userId, page, userFilter, search)
  );
}

export default useClientGigsDeatilsQuery;
