import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Image from "../../../../Assets/Images/Image";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import FolderIcon from "@mui/icons-material/Folder";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import {
  getAuthValue,
  setAuthValue,
} from "../../../AuthModule/Hooks/useAuthValue";
import WarehouseIcon from "@mui/icons-material/Warehouse";
const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowY: "hidden",
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  // transition: theme.transitions.create("width", {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  transition: "all .5s",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  width: "250px",
  height: "63px",
  overflow: "hidden",
  backgroundColor: "#fff",
  zIndex: 1,
  overflowY: "hidden",
  transition: "all .5s",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",

  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function Sidebar({
  handleDrawerClose,
  open,
  active,
  setActive,
}: any) {
  const theme = useTheme();
  const navigate = useNavigate();

  const value = getAuthValue();
  const path = useLocation();

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          position: "sticky",
          boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <DrawerHeader>
          <img src={Image.logo} alt="" height="48px" width="48px" />
          <Box position="absolute" right="-10px">
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <Box
                  bgcolor="text.primary"
                  height={"35px"}
                  width={"15px"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={"12px 0px 0px  12px"}
                >
                  <ChevronLeftIcon sx={{ color: "#fff " }} />
                </Box>
              )}
            </IconButton>
          </Box>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            // {
            //   icon: <HomeIcon sx={{ color: "inherit" }} />,
            //   name: "Dashboard",
            //   link: "/dashboard",
            // },
            {
              icon: <ReceiptIcon sx={{ color: "inherit" }} />,
              name: "Client Management",
              link: "/client-management",
            },
            {
              icon: <AccountBalanceWalletIcon sx={{ color: "inherit" }} />,
              name: "Supplier Management ",
              link: "/supplier-management",
            },
            // {
            //   icon: <AnalyticsIcon sx={{ color: "inherit" }} />,
            //   name: "Gig Management ",
            //   link: "/gig-management",
            // },
            // {
            //   icon: <BusinessCenterIcon sx={{ color: "inherit" }} />,
            //   name: "Service",
            //   link: "/Service",
            // },
            // {
            //   icon: <WarehouseIcon sx={{ color: "inherit" }} />,
            //   name: "Industry Type Management",
            //   link: "/industrytype-management",
            // },
            {
              icon: <NewspaperIcon sx={{ color: "inherit" }} />,
              name: "NewsLetter",
              link: "/newsLetter",
            },
            // {
            //   icon: <PieChartOutlineIcon sx={{ color: "inherit" }} />,
            //   name: "Subscription Management",
            //   link: "/subscription-management",
            // },
            // {
            //   icon: <InboxIcon sx={{ color: "inherit" }} />,
            //   name: "Transaction",
            //   link: "/underdevelopment",
            // },
            // {
            //   icon: <FolderIcon />,e
            //   name: "Report and Analysis",
            //   link: "/underdevelopment",
            // },
            // {
            //   icon: <LockRoundedIcon sx={{ color: "inherit" }} />,
            //   name: "Change Password",
            //   link: "/Change-Password",
            // },
          ].map((text, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                setAuthValue({
                  ...value,
                  active: text?.name,
                });
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 3,
                  bgcolor:
                    path?.pathname?.split("/")?.[1] ===
                    text?.link?.replace("/", "")
                      ? "text.primary"
                      : // :""
                        "text.primary",

                  ":hover": {
                    bgcolor:
                      path?.pathname?.split("/")?.[1] ===
                      text?.link?.replace("/", "")
                        ? "#5e17ebd9"
                        : // : "",
                          "#5e17ebd9",
                    color:
                      path?.pathname?.split("/")?.[1] ===
                      text?.link?.replace("/", "")
                        ? "white"
                        : // : "text.grey",
                          "white",
                  },
                }}
                onClick={() => {
                  navigate(text?.link);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : "auto",
                    justifyContent: "center",
                    color:
                      path?.pathname?.split("/")?.[1] ===
                      text?.link?.replace("/", "")
                        ? "white"
                        : // : "text.grey",
                          "white",
                  }}
                >
                  {text.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        path?.pathname?.split("/")?.[1] ===
                        text?.link?.replace("/", "")
                          ? "white"
                          : // : "text.grey",
                            "white",
                      fontSize: 13,
                      fontWeight: 600,
                    }}
                  >
                    {text.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}
