import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "../../../Utils/Helper";
import { CLIENT_LIST_RESPONSE_TYPES } from "../Types/ResponseTypes";

export const GET_CLIENT_LIST = (
  page: number,
  searchQuery: string,
  filterType?: string,
  status?: string,
  value?: string,
  startDate?: string,
  endDate?: string
) => [
  "client",
  page,
  searchQuery,
  filterType,
  status,
  value,
  startDate,
  endDate,
];
const ClientList = async (
  page: number,
  searchQuery: string,
  filterType?: string,
  status?: string,
  value?: string,
  startDate?: string,
  endDate?: string
): Promise<AxiosResponse<CLIENT_LIST_RESPONSE_TYPES>> => {
  return fetcher({
    url: `/admin/client?page=${page}&search=${searchQuery}&limit=10&filterType=${filterType}&status=${status}&value=${value}${
      filterType === "custom"
        ? `&startDate=${startDate}&endDate=${endDate}`
        : ``
    }`,
    method: "GET",
  });
};

function useClientQuery(
  page: number,
  searchQuery: string,
  filterType?: string,
  status?: string,
  value?: string,
  startDate?: string,
  endDate?: string
) {
  return useQuery(
    GET_CLIENT_LIST(
      page,
      searchQuery,
      filterType,
      status,
      value,
      startDate,
      endDate
    ),
    () =>
      ClientList(
        page,
        searchQuery,
        filterType,
        status,
        value,
        startDate,
        endDate
      )
  );
}

export default useClientQuery;
