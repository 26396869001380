import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "../../../Utils/Helper";
import { GIG_PROPOSAL_RESPONSE_TYPES } from "../Types/Response";

export const GET_GIGPROPOSAL_LIST = (
  id: string,
  page: number,
  supplierId: string
) => ["GigProposal", id, page, supplierId];

const GigPoposalList = async (
  id: string,
  page: number,
  supplierId: string
): Promise<AxiosResponse<GIG_PROPOSAL_RESPONSE_TYPES>> => {
  return fetcher({
    url: `/admin/gigs/proposals?gigId=${id}&page=${page}&supplierId=${supplierId}&limit=10`,
    method: "GET",
  });
};

function useGigProposalsQuery(id: any, page: number, supplierId: any) {
  return useQuery(GET_GIGPROPOSAL_LIST(id, page, supplierId), () =>
    GigPoposalList(id, page, supplierId)
  );
}

export default useGigProposalsQuery;
