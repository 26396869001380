import { Box, Pagination, Typography } from "@mui/material";
import Layout from "../../../LayoutModule/Components/Layout";
import BackButton from "../../../../Component/BackButton/BackButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ProposalCard from "../../../../Component/ProposalCard/ProposalCard";
import { useLocation, useParams } from "react-router-dom";
import useGigProposalsQuery from "../../../GigModule/Hooks/useGigProposalsQuery";
import { useEffect, useState } from "react";
import { GIG_PROPOSAL_DETAIL_LIST_TYPES } from "../../../GigModule/Types/Response";
import ProposalSkeletonLoader from "../../../../Component/ProposalSkeletonLoader/ProposalSkeletonLoader";

function ClientProposal() {
  const { id: gigId } = useParams();
  const [page, setPage] = useState(1);
  const supplierId = "";
  const { data } = useGigProposalsQuery(gigId, page, supplierId);
  const gigProposalQuery = useGigProposalsQuery(gigId, page, supplierId);
  const gigCreatedAt = data?.data?.data?.gigCreatedAt;
  const gigTitle = data?.data?.data?.gigTitle;
  const list = data?.data?.data?.list;
  const count = data?.data?.data?.count;
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const link = useLocation();
  return (
    <Layout>
      <Box position={"relative"}>
        <BackButton path={-1} />

        <>
          <Box py={1}>
            <Box display="flex" width={"100%"} justifyContent="space-between">
              <Box display="flex" alignItems="flex-start">
                <FiberManualRecordIcon
                  sx={{ color: "green", height: "15px", width: "15px" }}
                />
                <Typography
                  fontStyle={"italic"}
                  fontWeight={500}
                  fontSize={14}
                  px={0.5}
                  color={"text.lightgrey"}
                  key={gigCreatedAt}
                >
                  Gig Created Date: {gigCreatedAt?.substring(0, 10)}
                </Typography>
              </Box>
              <Typography
                textAlign="right"
                fontWeight={500}
                fontSize={14}
                color={"text.lightgrey"}
                px={2}
              >
                <b style={{ color: "black", paddingRight: "2px" }}>
                  {count ? count : "0"}
                </b>
                request found{" "}
              </Typography>
            </Box>
            <Typography
              variant="h4"
              fontWeight={600}
              py={2}
              textTransform={"capitalize"}
              key={gigTitle}
            >
              {gigTitle}
            </Typography>
          </Box>
          {gigProposalQuery?.isError ? (
            <Typography
              variant="h5"
              p={10}
              color={"black"}
              textAlign={"center"}
              fontWeight={600}
            >
              No proposal found!
            </Typography>
          ) : (
            <Box
              py={2}
              width={"100%"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection={"column"}
            >
              {gigProposalQuery?.isLoading ? (
                <ProposalSkeletonLoader number={5} />
              ) : (
                <>
                  {list?.map((item: GIG_PROPOSAL_DETAIL_LIST_TYPES) => (
                    <ProposalCard
                      src={item?.supplier_data?.companyLogo}
                      companyName={
                        item?.supplier_data?.companyName
                          ? item?.supplier_data?.companyName
                          : "Not Available"
                      }
                      rating={4.5}
                      path={
                        link?.pathname?.split("/")?.[1] === "client-management"
                          ? `/client-management/proposal-details/${item?._id}`
                          : `/gig-management/proposal-details/${item?._id}`
                      }
                      reason={item?.reason}
                      key={item?._id}
                    />
                  ))}
                </>
              )}
            </Box>
          )}
          <Pagination
            // count={totalPages}
            onChange={handleChange}
            page={page}
            shape="rounded"
            sx={{
              float: "right",
              boxShadow: "none",
              my: 2,
              color: "text.lightgrey",
            }}
          />
        </>
      </Box>
    </Layout>
  );
}

export default ClientProposal;
