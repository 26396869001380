import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Pagination, IconButton, Switch, Paper, Avatar, Typography } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  CLIENT_LIST_ITEM, CLIENT_LIST_RESPONSE_TYPES,
} from "../../Types/ResponseTypes";
import useClientStatusMutation from "../../Hooks/useClientStatusMutation";
import SearchInput from "../../../../Component/SearchInput/SearchInput";
import SnackbarHandler from "../../../../Utils/SnackbarHandler";
import ToolTip from "../../../../Component/Tooltip/Tooltip";
import { AxiosResponse } from "axios";
import { UseQueryResult } from "react-query";
import TableSkeletonLoader from "../../../../Component/TableSkeletonloader/TableSkeletonLoader";
type ClientProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  totalPages: number | any;
  currentPage: number | any;
  clients: Array<CLIENT_LIST_ITEM> | any;
  ClientQuery: UseQueryResult<AxiosResponse<CLIENT_LIST_RESPONSE_TYPES, any>>
};
const style = {
  color: "common.black",
  fontWeight: 600,
  fontSize: { lg: 16, md: 14, sm: 12, xs: 10, },
}
const tableCellstyle = {
  color: "text.grey",
  fontWeight: 500,
  fontSize: { lg: 16, md: 14, sm: 12, xs: 10, },
  overflow: "hidden"
}
export default function ClientManagemnetTable(props: ClientProps) {
  const { page, setSearchQuery, searchQuery, setPage, clients, totalPages, ClientQuery, currentPage } = props;
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  }

  const navigate = useNavigate()
  const clientStatusMutation = useClientStatusMutation();


  return (
    <Box>
      <Box width={"30%"} pb={2} sx={{ float: "left", }}>
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder="Search Full Name/Email"
        />
      </Box>

      {ClientQuery?.isError ? <Typography variant='h5' p={25} color={"black"} textAlign={"center"} fontWeight={600}> No Clients Found! </Typography> :
        <Paper>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ bgcolor: "#D9D9D9" }}>
                  {["S.no", "Name", "Email Id", "Company Name", "Website", "Profile Pic ", "Phone Number", "View Details", " Eanble/Disable"].map((item: string) => <TableCell
                    sx={style}
                    align="center"
                    key={item}
                  >
                    {item}
                  </TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>

                {ClientQuery?.isLoading ? <TableSkeletonLoader rows={10} column={9} /> : <>
                  {clients && clients?.map((item: CLIENT_LIST_ITEM, index: number) => (
                    <TableRow
                      key={item._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        align="center"
                        width="5%"
                        sx={tableCellstyle}
                      >
                        {10 * (currentPage - 1) + (index + 1)}

                      </TableCell>
                      <TableCell
                        component="th"
                        align="center"
                        sx={tableCellstyle}
                      >
                        {item.fullName ? item.fullName : "Not Available"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableCellstyle}
                      >
                        {item.email ? item.email : "Not Available"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableCellstyle}
                      >
                        {item?.companyName ? item?.companyName : "Not Available"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableCellstyle}
                      >
                        {item?.companyWebsite ? item?.companyWebsite?.substring(8, 25) : "Not Available"}
                      </TableCell>
                      <TableCell align="center" sx={tableCellstyle}>
                        {item?.companyLogo ?
                          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexWrap={"nowrap"} >
                            <Avatar src={item?.companyLogo} alt={item?.companyName} />
                          </Box> : "Not Available"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableCellstyle}
                      >
                        {item?.countryCode}{item.phoneNumber ? item.phoneNumber : "Not Available"}
                      </TableCell>
                      <TableCell align="center">
                        <ToolTip title="View Details" placement="bottom" arrow>

                          <IconButton
                            onClick={() => {
                              navigate(`/client-management/clientDetails/${item?._id}`)
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        </ToolTip>
                      </TableCell>
                      <TableCell align="center">
                        <Switch
                          color="success"
                          onChange={() =>
                            clientStatusMutation.mutate({
                              id: item?._id,
                              status: !item.status,
                            }, {
                              onSuccess: (response: any) => {
                                SnackbarHandler.successToast(response?.data?.message)
                              }
                            })
                          }
                          checked={item.status}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>}
      <Pagination
        count={totalPages}
        onChange={handleChange}
        page={page}
        shape="rounded"
        sx={{
          float: "right",
          boxShadow: "none",
          my: 2,
          color: "text.lightgrey",
        }}
      />
    </Box >

  );
}
