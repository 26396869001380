import { Box, Button } from '@mui/material'

import { UseFormReset } from 'react-hook-form'
type modalFormButtonProps = {
    reset: UseFormReset<any>
}
function ModalFormButton(props: modalFormButtonProps) {
    const { reset } = props
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Button
          type="submit"
          sx={{
            width: "70%",
            mt: 5,
            mb: 2,
            ":hover": {
              bgcolor: "#7638f2",
              color: "white",
            },
            color: "white",
            bgcolor: "text.primary",
          }}
        >
          Update
        </Button>
        <Button
          type="reset"
          variant="text"
          onClick={() => reset({})}
          sx={{
            ":hover": {
              bgcolor: "#7638f2",
              color: "white",
            },
            width: "70%",
            color: "white",
            bgcolor: "text.primary",
          }}
        >
          Reset
        </Button>
      </Box>
    );
}

export default ModalFormButton