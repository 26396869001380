import { LOGIN_REQUEST } from "../Types/RequestTypes";

import { fetcher } from "../../../Utils/Helper";
import { AxiosResponse } from "axios";
import { LOGIN_RESPONSE } from "../Types/ResponseTypes";
import { useMutation } from "react-query";

const login = async (
  data: LOGIN_REQUEST
): Promise<AxiosResponse<LOGIN_RESPONSE>> => {
  return fetcher({
    url: "/admin/auth/login",
    method: "POST",
    data: data,
  });
};
function useLoginMutation() {
  return useMutation(login);
}
export default useLoginMutation;
