import { Fragment, useState } from "react";
import {
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from "react-hook-form";
import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  TextField,
  Typography,
} from "@mui/material";

type InputTextProps = {
  label?: string;
  field: ControllerRenderProps<FieldValues, string>;
  textInputProps?: OutlinedInputProps;
  formState: UseFormStateReturn<FieldValues>;
  left?: any;
  leftWidth?: string | number;
  right?: any;
  rightWidth?: string | number;
  customClassName?: string;
  LeftIcon?: () => JSX.Element;
  RightIcon?: () => JSX.Element;
  value?: string;
  onChange?: any;
};

function InputText(props: InputTextProps) {
  const {
    label,
    field,
    formState,
    left,
    right,
    textInputProps,
    RightIcon,
    LeftIcon,
    onChange,
  } = props;
  const errorMessage = formState.errors?.[field.name]?.message;
  const [focus, setFocus] = useState(false);
  const labelColor = errorMessage
    ? "error"
    : field.value
    ? "primary"
    : focus
    ? "primary"
    : "text";

  return (
    <div className="">
      {label ? (
        <label
          htmlFor={field.name}
          color={labelColor}
          className={
            "text-[1.6rem] leading-normal text-light-primaryBlack mb-[1.2rem]"
          }
        >
          {label}
        </label>
      ) : (
        <Fragment />
      )}
      <div className={`relative mt-[1.5rem]`}>
        {left && left}
        <OutlinedInput
          type="text"
          className="input-focus"
          // value={value}

          id={field?.name}
          placeholder={field?.name}
          fullWidth
          sx={{
            color: "text.lightgrey",
            ":focus": {
              borderColor: "text.primary",
              ":focus": { outline: "none" },
            },
          }}
          onFocus={() => setFocus(true)}
          {...field}
          onBlur={() => {
            field.onBlur();
            setFocus(false);
          }}
          {...textInputProps}
          endAdornment={
            RightIcon && (
              <InputAdornment position="end">
                <RightIcon />
              </InputAdornment>
            )
          }
          startAdornment={
            LeftIcon && (
              <InputAdornment position="end">
                <LeftIcon />
              </InputAdornment>
            )
          }
        />
        {right}
      </div>
      {errorMessage && (
        // <div className={"text-lightRed"}>{errorMessage?.toString()}</div>
        <Typography
          color="#F62020"
          textAlign="right"
          fontSize="12px"
          lineHeight="10px"
          fontWeight={400}
          mt={0.3}
        >
          {errorMessage?.toString()}
        </Typography>
      )}
    </div>
  );
}

export default InputText;
