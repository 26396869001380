import { Typography } from "@mui/material";
import React from "react";
import { getRequiredRules } from "../../Utils/validator";
import FormInput from "../FormInput/FormInput";
import { Control, FieldValues } from "react-hook-form";
import { INPUT_TYPES } from "../../Utils/Types";
type modalInputProps = {
  label: string;
  control: Control<any, any>;
  placeholder: string;
  name: string;
  type: INPUT_TYPES;
  textInputProps?: any;
  RightIcon?: any;
  onChange?: any;
};
function ModalFormInput(props: modalInputProps) {
  const {
    label,
    control,
    placeholder,
    name,
    type,
    RightIcon,
    onChange,
    textInputProps,
  } = props;
  return (
    <>
      <Typography
        color="text.lightgrey"
        textAlign="left"
        fontSize="12px"
        lineHeight="10px"
        fontWeight={600}
        mb={1}
        mt={2}
        textTransform={"capitalize"}
      >
        {label}
      </Typography>
      <FormInput
        name={name}
        control={control}
        type={type}
        placeholder={placeholder}
        rules={getRequiredRules(name)}
        OutlinedInputProps={{
          my: 1,
          color: "text.ligthgrey",
        }}
        textInputProps={textInputProps}
        RightIcon={RightIcon}
      />
    </>
  );
}

export default ModalFormInput;
