import { Box, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@mui/material'
import { Visibility } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import SearchInput from '../../../../Component/SearchInput/SearchInput'
import ToolTip from '../../../../Component/Tooltip/Tooltip'
import { CLIENT_GIGDETAILS_RESPONSE_TYPES, CLIENT_GIG_LIST } from '../../Types/ResponseTypes'
import { AxiosResponse } from 'axios'
import { UseQueryResult } from 'react-query'
import TableSkeletonLoader from '../../../../Component/TableSkeletonloader/TableSkeletonLoader'

const style = {
  color: "common.black",
  fontWeight: 600,
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
}
const tablebodyStyle = {
  color: "text.grey",
  fontWeight: 500,
  overflow: "hidden",
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },

}

type ClientGigListProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  gigs?: any;
  totalPages?: number | any;
  searchQuery: string,
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>,
  clientGigQuery: UseQueryResult<AxiosResponse<CLIENT_GIGDETAILS_RESPONSE_TYPES, any>>
};
function ClientGigsTable(props: ClientGigListProps) {
  const { page, setPage, gigs, totalPages, searchQuery, setSearchQuery, clientGigQuery } = props
  const { id: clientId } = useParams()

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const navigate = useNavigate()

  return (

    <Box py={2}>
      <Box width={"30%"} px={1} pb={2} >

        <SearchInput placeholder={'search gigs'} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </Box>
      {clientGigQuery?.isError ? <Typography variant='h5' p={10} color={"black"} textAlign={"center"} fontWeight={600}> No Gigs Found! </Typography> :
        <Paper>
          <>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: "#D9D9D9" }}>
                    {["S.no", "Title", "Brief Description", "Gig Created Date", "Gig Expiry Date", "Gig Deatils", "Proposals"].map((item: string) => <TableCell
                      align="center"
                      sx={style}
                      key={item}
                    >
                      {item}
                    </TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientGigQuery?.isLoading ? <TableSkeletonLoader rows={10} column={7} /> :
                    <>
                      {gigs.map((item: CLIENT_GIG_LIST, index: number) => (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell
                            align="center"
                            width="5%"
                            sx={tablebodyStyle}
                          >
                            {10 * (page - 1) + (index + 1)}
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tablebodyStyle}
                          >
                            {item?.title}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tablebodyStyle}
                            width={"30%"}

                          >
                            {item?.description.substring(0, 50)}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tablebodyStyle}
                          >
                            {item?.createdAt.substring(0, 10)}
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tablebodyStyle}
                          >
                            {item?.expiryDate.substring(0, 10)}
                          </TableCell>

                          <TableCell align="center">
                            <ToolTip title="Gig Details" placement='bottom' arrow>
                              <IconButton
                                onClick={() => {
                                  navigate(`/client-management/client-gigdetail/${item?._id}`);
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </ToolTip>
                          </TableCell>
                          <TableCell align="center">
                            <ToolTip title="Proposals" placement='bottom' arrow>
                              <IconButton
                                onClick={() => {
                                  navigate(`/client-management/proposal/${item?._id}`);
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </ToolTip>
                          </TableCell>

                        </TableRow>
                      ))}
                    </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Paper>
      }
      <Pagination
        count={totalPages}
        onChange={handleChange}
        page={page}
        shape="rounded"

        sx={{
          float: "right",
          boxShadow: "none",
          my: 2,
        }}
      />
    </Box>)
}

export default ClientGigsTable