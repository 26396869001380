import "../src/Assets/Style/global.css";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material";
import theme from "./Assets/Style/Theme";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import navigationRouter from "./Navigation/Navigation";
import { Fragment, useEffect, useState } from "react";
import { queryClient } from "./Utils/ReactQueryConfig";
import { QueryClientProvider } from "react-query";

function App() {
  useEffect(() => {
    setMounted(true);
  }, []);
  const [mouted, setMounted] = useState(false);
  return mouted ? (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={navigationRouter} />
        </ThemeProvider>
        <ToastContainer icon={false} />
      </QueryClientProvider>
    </>
  ) : (
    <Fragment />
  );
}

export default App;
