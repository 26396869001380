import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "../../../Utils/Helper";
import { NEWSLETTER_LIST_RESPONSE_TYPES } from "../Types/ResponseTypes";

export const GET_NEWSLETTER_LIST = (
  page: number,
  searchQuery: string,
  limit?: number
  // filterType?: number
) => [
  "newsletter",
  searchQuery ? 1 : page,
  searchQuery,
  limit,
  // filterType
];

const NewsLetterDetails = async (
  page: number,
  searchQuery: string,
  limit?: number,
  filterType?: number
): Promise<AxiosResponse<NEWSLETTER_LIST_RESPONSE_TYPES>> => {
  return fetcher({
    url: `/admin/subscribe-news-letter?page=${
      searchQuery ? 1 : page
    }&search=${searchQuery}&limit=${limit}`,
    method: "GET",
  });
};

function useNewLetterQuery(
  page: number,
  searchQuery: string,
  limit?: number,
  filterType?: number
) {
  return useQuery(GET_NEWSLETTER_LIST(page, searchQuery, limit), () =>
    NewsLetterDetails(page, searchQuery, limit)
  );
}

export default useNewLetterQuery;
