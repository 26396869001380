
import {

  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import BackButton from "../../../../Component/BackButton/BackButton";
import ProfileCard from "../../../../Component/ProfileCard/ProfileCard";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../LayoutModule/Components/Layout";
import useSupplierDetailsQuery from "../../Hooks/useSupplierDetailsQuery";

function SupplierProfile() {
  const { id: supplierId } = useParams()
  const { data } = useSupplierDetailsQuery(supplierId)
  const supplierDetails = data?.data?.data


  const navigate = useNavigate()
  return (
    <Layout>

      <Paper>
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <BackButton path={-1} />
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          // p={5}
          >
            <Box display="flex" alignItems={"center"} pl={5} >
              <Box p={1} height="150px" width="150px" display='flex' justifyContent='center' alignItems={"center"}>
                {supplierDetails?.companyLogo ? <img
                  alt={supplierDetails?.companyName}
                  src={supplierDetails?.companyLogo}
                  height="100%" width="100%"
                  style={{ objectFit: "contain" }}
                /> : <Typography color={"text.grey"}>Image NA</Typography>}
              </Box>
              <Box>
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  px={2}
                  sx={{ color: "text.grey" }}
                  textAlign="left"
                  textTransform="capitalize"
                >
                  {supplierDetails?.fullName ? supplierDetails?.fullName : "Not Available"}
                </Typography>
                <Typography textAlign="left" p={1} sx={{ color: "text.grey" }}>
                  {supplierDetails?.countryCode}{supplierDetails?.phoneNumber ? supplierDetails?.phoneNumber : "Not Available "}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              // justifyContent={"center"}
              px={3}
              width={"50%"}
            >
              <Typography
                fontSize={20}
                fontWeight={600}
                p={1}
                textAlign="left"
                sx={{ color: "text.grey" }}
              >
                Id Proof :
              </Typography>

              <Box

                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >

                {supplierDetails?.legalIdNo ? (
                  <Box p={3}>
                    <img
                      src={supplierDetails?.legalId}
                      alt="not available"
                      width={"185px"}
                      height={"150px"}
                      style={{ zIndex: 9 }}
                    />
                    <Typography
                      pt={1}

                      variant="body1"
                      sx={{ color: "text.grey" }}
                    >
                      {" "}
                      LegalId No : {supplierDetails?.legalIdNo ? supplierDetails?.legalIdNo : "Not Available"}
                    </Typography>
                  </Box>
                ) : (
                  <Typography
                    variant="body1"
                    p={10}
                    noWrap
                    sx={{ color: "text.grey" }}
                  >
                    Not Available
                  </Typography>
                )}
              </Box>

            </Box>
          </Box>
          <Box
            width={"100%"}
            display="flex"
            justifyContent={"space-between"}
            py={5}
            px={4}
          >

            <Box width={"50%"}>
              <Box alignItems="center" width={"100%"}>
                <ProfileCard
                  heading="Company Name"
                  content={supplierDetails?.companyName ? supplierDetails?.companyName : "Not Available"}
                />
                <ProfileCard
                  heading="Industry Type"
                  content={supplierDetails?.industryType?.length !== 0 ? supplierDetails?.industryType : "Not Available"}
                />
                <ProfileCard
                  heading="Zip Code"
                  content={supplierDetails?.address?.zipCode ? supplierDetails?.address?.zipCode : "Not Available"}
                />
                <ProfileCard
                  heading="City"
                  content={supplierDetails?.address?.city ? supplierDetails?.address?.city : "Not Available"}
                />
                <ProfileCard
                  heading="Address"
                  content={supplierDetails?.address?.address ? supplierDetails?.address?.address : "Not Available"}
                />
                <ProfileCard
                  heading="Service"
                  content={supplierDetails?.skills?.length !== 0 ? supplierDetails?.skills?.join(" , ") : "Not Available"}
                />
                <ProfileCard
                  heading="Speciality"
                  content={supplierDetails?.specialty?.length !== 0 ? supplierDetails?.specialty : "Not Available"}
                />
              </Box>
            </Box>
            <Box width={"50%"}>
              <Box alignItems="center" width={"100%"}>
                <ProfileCard
                  heading="Company Website"
                  content={supplierDetails?.companyWebsite ? supplierDetails?.companyWebsite : "Not Available"}
                />
                <ProfileCard
                  heading="Country"
                  content={supplierDetails?.address?.country ? supplierDetails?.address?.country : "Not Available"}
                />
                <ProfileCard
                  heading="State"
                  content={supplierDetails?.address?.state ? supplierDetails?.address?.state : "Not Available"}
                />
                <Box display="flex" alignItems="center" width={"100%"}>
                  <Box width={"48%"}>
                    <Typography
                      fontSize={20}
                      fontWeight={600}
                      py={1}
                      pl={4}

                      sx={{ color: "text.grey" }}
                      textAlign="left"
                    >
                      Gigs :
                    </Typography>
                  </Box>
                  <Button variant="outlined" onClick={() => {
                    navigate(
                      `/supplier-management/gigs?supplierId=${supplierDetails?._id}`
                    );
                  }}>
                    View Gigs
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Layout>
  );
}
export default SupplierProfile;
