import { Box, Typography } from "@mui/material";
import React from "react";

export default function ProfileCard({ heading, content }: any) {
  return (
    <Box
      display="flex"
      alignItems="center"
      // justifyContent={"space-between"}
      py={1}
      width={"100%"}
      overflow={"hidden"}
    >
      <Box width={"45%"}>
        <Typography
          fontSize={20}
          fontWeight={600}
          py={1}
          noWrap
          textTransform={"capitalize"}
          px={4}
          textAlign="left"
          sx={{ color: "text.grey" }}
        >
          {heading} :
        </Typography>
      </Box>
      <Box width={"55%"} textAlign={"left"}>
        <Typography
          fontSize={18}
          fontWeight={500}
          py={1}
          px={4}
          width={"100%"}
          //   textAlign="right"
          sx={{ color: "text.lightgrey" }}
          textTransform="capitalize"
        >
          {content}
        </Typography>
      </Box>
    </Box>
  );
}
