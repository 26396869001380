import {
  Backdrop,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SearchInput from "../../../../Component/SearchInput/SearchInput";
import SupplierRequestTable from "../SupplierRequestTable/SupplierRequestTable";
import SupplierListTable from "../SupplierListTable/SupplierListTable";
import useSupplierQuery from "../../Hooks/useSupplierQuery";
import { useDebounce } from "use-debounce";
import AddSupplierForm from "../AddSupplierForm/AddSupplierForm";
import { useParams, useSearchParams } from "react-router-dom";
import SupplierProfilePage from "../SupplierProfile/SupplierProfilePage";

function SupplierMangement() {
  // const [searchParams] = useSearchParams();
  const [tab, setTab] = useState("supplierList");
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [search] = useDebounce(searchQuery, 1000);
  // const { filterType }: any = useParams()
  const [searchParam, setSearchParams] = useSearchParams();
  const filterType: string | any = searchParam.get("filterType");
  const status: string | any = searchParam.get("status");
  const value: string | any = searchParam.get("value");
  const startDate: string | any = searchParam.get("startDate");
  const endDate: string | any = searchParam.get("endDate");
  const { data } = useSupplierQuery(
    page,
    search,
    tab,
    filterType,
    status,
    value,
    startDate,
    endDate
  );
  const supplierQuery = useSupplierQuery(
    page,
    search,
    tab,
    filterType,
    status,
    value,
    startDate,
    endDate
  );
  const { totalPages, suppliers, currentPage } = data?.data?.data ?? {};
  const [backdropOpen, setbackdropOpen] = useState(Boolean);
  const handleOpen = () => {
    setbackdropOpen(true);
  };
  const handleClose = () => {
    setbackdropOpen(false);
  };

  // const SupplierProfile: any = suppliers?.filter(
  //   (item) => item._id === supplierId
  // );
  return (
    <>
      {/* {!supplierId ? ( */}
      <Box>
        <Box
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexWrap={"nowrap"}
        >
          <Box width={"30%"} m={1}>
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search Full Name / Email"
            />
          </Box>
          {tab === "supplierList" ? (
            <Button
              onClick={() => handleOpen()}
              // color="success"
              variant="contained"
              sx={{
                color: "white",
                bgcolor: "text.primary",
                ":hover": {
                  bgcolor: "#7638f2",
                  color: "white",
                },
              }}
            >
              Add Supplier
            </Button>
          ) : (
            ""
          )}
          <Backdrop open={backdropOpen} sx={{ zIndex: 3 }}>
            <AddSupplierForm handleClose={handleClose} />
          </Backdrop>
        </Box>
        <Box>
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-evenly"}
            sx={{ color: "text.grey" }}
          >
            <div
              onClick={() => {
                setTab("supplierRequestList");
                setPage(1);
              }}
              style={{ cursor: "pointer" }}
            >
              <Typography
                fontWeight={600}
                px={2}
                py={1}
                borderBottom={tab === "supplierRequestList" ? 3 : 0}
                borderColor={"text.grey"}
              >
                Supplier Request List
              </Typography>
            </div>
            <div
              onClick={() => {
                setTab("supplierList");
                setPage(1);
              }}
              style={{ cursor: "pointer" }}
            >
              <Typography
                fontWeight={600}
                px={2}
                py={1}
                borderBottom={tab === "supplierList" ? 3 : 0}
                borderColor={"text.grey"}
              >
                Supplier List
              </Typography>
            </div>
          </Stack>
          <Divider />
          {tab === "supplierList" ? (
            <SupplierListTable
              page={page}
              currentPage={currentPage}
              setPage={setPage}
              totalPages={totalPages}
              suppliers={suppliers}
              supplierQuery={supplierQuery}
            />
          ) : (
            <>
              <SupplierRequestTable
                page={page}
                setPage={setPage}
                currentPage={currentPage}
                totalPages={totalPages}
                suppliers={suppliers}
                supplierQuery={supplierQuery}
              />
            </>
          )}
        </Box>
      </Box>
      {/* ) 
       : (
        <SupplierProfilePage supplierDetails={SupplierProfile[0]} cb={() => setSearchQuery("")} />
       )} */}
    </>
  );
}
export default SupplierMangement;
