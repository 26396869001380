// import { Container, Box, Paper } from "@mui/material";
// import SideDrawer from "../../Component/SideDrawer/SideDrawer";
// import Grid from "@mui/material/Grid";
// import ClientManagemnetTable from "../../Component/ClientManagementTable/ClientManagemnetTable";

// export default function ClientManagement() {
//   return (
//     <>
//       <Container
//         disableGutters
//         maxWidth={false}
//         className="container"
//         sx={{ backgroundColor: "primary.light" }}
//       >
//         <Box sx={{ display: "flex" }}>
//           <SideDrawer />
//           <Box
//             component="main"
//             m={0}
//             p={2}
//             sx={{
//               flexGrow: 1,
//               pt: { lg: 12, md: 12, sm: 8, xs: 8 },
//               pl: { lg: 10, md: 10, sm: 10, xs: 8 },
//             }}
//             width={"100%"}
//           >
//             <Box
//               display="flex"
//               sx={{
//                 flexDirection: {
//                   lg: "row",
//                   md: "column",
//                   sm: "column",
//                   xs: "column",
//                 },
//               }}
//               width="100%"
//             >
//               <Box
//                 sx={{
//                   width: "100%",
//                 }}
//               >
//                 {/* <Grid
//                   container
//                   rowSpacing={1}
//                   columnSpacing={{ xs: 1, sm: 2, md: 3 }}
//                 > */}
//                 <Paper>
//                   <ClientManagemnetTable />
//                 </Paper>
//                 {/* </Grid> */}
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Container>
//     </>
//   );
// }
import React from "react";
import Layout from "../../Modules/LayoutModule/Components/Layout";
import ClientManagement from "../../Modules/ClientModule/Component/ClientMangement/ClientManagement";

function ClientManagementPage() {
  return (
    <Layout>
      <ClientManagement />
    </Layout>
  );
}

export default ClientManagementPage;
