import {
  Box,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BackButton from "../../../../Component/BackButton/BackButton";
import SearchInput from "../../../../Component/SearchInput/SearchInput";
import { Visibility } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import DropdownButton from "../../../../Component/DropdownButton/DropdownButton";

import { useState } from "react";
import ToolTip from "../../../../Component/Tooltip/Tooltip";
import useSupplierGigsQuery from "../../Hooks/useSupplierGigQuery";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { SUPPLIER_GIG_LIST } from "../../Types/ResponseTypes";
import TableSkeletonLoader from "../../../../Component/TableSkeletonloader/TableSkeletonLoader";
import { useDebounce } from "use-debounce";
const style = {
  color: "common.black",
  fontWeight: 600,
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
};
const tablebodyStyle = {
  color: "text.grey",
  fontWeight: 500,
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
};

function SupplierGigsTable() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [checkedItems, setchekedItems] = useState<Array<string>>([]);
  const supplierId = searchParams.get("supplierId");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [search] = useDebounce(searchQuery, 1000);
  const [status, setStatus] = useState();
  const { data } = useSupplierGigsQuery(
    search,
    page,
    supplierId,
    "2",
    status,
    checkedItems
  );
  const supplierGigQuery = useSupplierGigsQuery(
    search,
    page,
    supplierId,
    "2",
    status,
    checkedItems
  );
  const supplierGigsList = data?.data?.data?.list;

  const menu = [
    { text: "All Gigs", status: undefined },
    { text: "Gig in Progress", status: 2 },
    { text: "Completion Request", status: 4 },
    { text: "Gig On hold", status: 3 },
    { text: "Gig Completed", status: 5 },
    { text: "Industry Type" },
  ];
  return supplierId ? (
    <Box position="relative">
      <BackButton path={-1} />
      <Box py={2}>
        <Box
          display={"flex"}
          sx={{ float: "right" }}
          borderRadius={"5px"}
          mx={1}
        >
          <DropdownButton
            title="Filter"
            menu={menu}
            setStatus={setStatus}
            setchekedItems={setchekedItems}
            checkedItems={checkedItems}
            icon={<FilterAltIcon sx={{ color: "white " }} />}
            color="white"
            backgroundColor="text.primary"
            variant="contained"
          />
        </Box>
        <Box width="30%" sx={{ float: "left" }}>
          <SearchInput
            placeholder="Search Gig"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Box>
        <Typography
          align="center"
          fontSize={25}
          fontWeight={600}
          color={"common.black"}
        >
          Supplier Gigs
        </Typography>
      </Box>
      {supplierGigQuery?.isError ? (
        <Typography
          variant="h5"
          p={10}
          color={"black"}
          textAlign={"center"}
          fontWeight={600}
        >
          {" "}
          Gig not found!{" "}
        </Typography>
      ) : (
        <Paper sx={{ py: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ bgcolor: "#D9D9D9" }}>
                  {[
                    "S.no",
                    "Title",
                    "Client",
                    "Brief Description",
                    "Status",
                    "Gig Created Date",
                    "Gig Expiry Date",
                    "View",
                  ].map((item: string) => (
                    <TableCell align="center" sx={style} key={item}>
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {supplierGigQuery?.isLoading ? (
                  <TableSkeletonLoader rows={10} column={8} />
                ) : (
                  <>
                    {supplierGigsList?.map(
                      (item: SUPPLIER_GIG_LIST, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="center"
                            width="5%"
                            sx={tablebodyStyle}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="center" sx={tablebodyStyle}>
                            {item?.title ? item?.title : "Not Available"}
                          </TableCell>
                          <TableCell align="center" sx={tablebodyStyle}>
                            {item?.clientName
                              ? item?.clientName
                              : "Not Available"}
                          </TableCell>
                          <TableCell align="center" sx={tablebodyStyle}>
                            {item?.description
                              ? item?.description
                              : "Not Available"}
                          </TableCell>
                          <TableCell align="center" sx={tablebodyStyle}>
                            {item?.status === 1
                              ? "newGig"
                              : item?.status === 2
                              ? "Gig In Progress"
                              : item.status === 3
                              ? "Hold"
                              : item.status === 4
                              ? "Completion Request"
                              : item.status === 5
                              ? "Completed"
                              : "Not Available"}
                          </TableCell>
                          <TableCell align="center" sx={tablebodyStyle}>
                            {item?.createdAt
                              ? item?.createdAt.substring(0, 10)
                              : "Not Available"}
                          </TableCell>

                          <TableCell align="center" sx={tablebodyStyle}>
                            {item?.expiryDate.substring(0, 10) ??
                              "Not Available"}
                          </TableCell>
                          <TableCell align="center">
                            <ToolTip
                              title="Gigs Detail"
                              placement="bottom"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  navigate(
                                    `/supplier-management/gigs-Detail/${item?._id}`
                                  );
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </ToolTip>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            //   count={totalPages}
            //   onChange={handleChange}
            page={page}
            shape="rounded"
            sx={{
              float: "right",
              boxShadow: "none",
              my: 2,
              color: "text.lightgrey",
            }}
          />
        </Paper>
      )}
    </Box>
  ) : (
    <p>no gigs found </p>
  );
}
export default SupplierGigsTable;
