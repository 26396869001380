import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
} from "@mui/material";
import SearchInput from "../../../../Component/SearchInput/SearchInput";
import TableSkeletonLoader from "../../../../Component/TableSkeletonloader/TableSkeletonLoader";
import useNewLetterQuery from "../../Hooks/useNewsLetterQuery";
import { useState } from "react";
import { NEWSLETTER_LIST_ITEM } from "../../Types/ResponseTypes";
import { last } from "rxjs";

const tablebodyStyle = {
  color: "text.grey",
  fontWeight: 500,
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
};
const Style = {
  color: "common.black",
  fontWeight: 600,
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
};
function NewsLetterTable() {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const limit = 10;
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const { data } = useNewLetterQuery(page, searchQuery, limit);
  const newsletterQuery = useNewLetterQuery(page, searchQuery, limit);
  const news = data?.data?.data?.news;
  const totalpage = data?.data?.data?.totalPages;
  const currentPage: any = data?.data?.data?.currentPage;

  return (
    <Box>
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"nowrap"}
      >
        <Box width={"30%"} m={1}>
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder="Search Email/Name"
          />
        </Box>
      </Box>

      {newsletterQuery?.isError ? (
        <Typography
          variant="h5"
          p={10}
          color={"black"}
          textAlign={"center"}
          fontWeight={600}
        >
          {" "}
          No Newsletter Found!
        </Typography>
      ) : (
        <>
          <Paper sx={{ py: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: "#D9D9D9" }}>
                    {[
                      "S.no",
                      "First Name",
                      "Last Name",
                      "Email",
                      "Phone Number",
                      "Interested in",
                      "Preferred method of communication",
                    ].map((item: string) => (
                      <TableCell align="center" sx={Style} key={item}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newsletterQuery?.isLoading ? (
                    <TableSkeletonLoader rows={10} column={5} />
                  ) : (
                    <>
                      {news &&
                        news?.map(
                          (item: NEWSLETTER_LIST_ITEM, index: number) => {
                            return (
                              <TableRow
                                key={`newsletter${index}`}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  align="center"
                                  width="5%"
                                  sx={tablebodyStyle}
                                >
                                  {10 * (currentPage - 1) + (index + 1)}
                                </TableCell>
                                <TableCell align="center" sx={tablebodyStyle}>
                                  {item.fname}
                                </TableCell>
                                <TableCell align="center" sx={tablebodyStyle}>
                                  {item.lname}
                                </TableCell>
                                <TableCell align="center" sx={tablebodyStyle}>
                                  {item?.email}
                                </TableCell>
                                <TableCell align="center" sx={tablebodyStyle}>
                                  {item.phoneNumber && item.countryCode
                                    ? `${item?.countryCode} ${item.phoneNumber}`
                                    : "N/A"}
                                </TableCell>
                                <TableCell align="center" sx={tablebodyStyle}>
                                  {item?.interestedIn === 1
                                    ? "Getting Services"
                                    : "Providing Services"}
                                </TableCell>
                                <TableCell align="center" sx={tablebodyStyle}>
                                  {item?.question === 2
                                    ? "Phone"
                                    : item?.question === 1
                                    ? "Email"
                                    : "N/A"}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Pagination
            count={totalpage}
            onChange={handleChange}
            page={page}
            shape="rounded"
            sx={{
              float: "right",
              boxShadow: "none",
              my: 2,
              color: "GrayText",
            }}
          />
        </>
      )}
    </Box>
  );
}

export default NewsLetterTable;
