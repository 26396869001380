import { Container, Box } from "@mui/material";
import { useState, Fragment } from "react";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar"


type LAYOUT_PROPS = {
  children: any;
};
function Layout(props: LAYOUT_PROPS) {
  const { children } = props;
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Container
        disableGutters
        maxWidth={false}
        className="container"
        sx={{ backgroundColor: "primary.light" }}
      >
        <Box sx={{ display: "flex" }}>
          <Header handleDrawerOpen={handleDrawerOpen} open={open} />

          <Box
            component="main"
            m={0}
            p={2}
            sx={{
              flexGrow: 1,
              pt: { lg: 12, md: 12, sm: 8, xs: 8 },
              pl: open ? " 260px" : { lg: 10, md: 10, sm: 8, xs: 8 },
              overflowX: "hidden",
              transition: "all .5s"
            }}

            width={"100%"}
          >
            {children}
          </Box>
        </Box>
      </Container>
      <Sidebar handleDrawerClose={handleDrawerClose} open={open} />
    </Fragment>
  );
}

export default Layout;
