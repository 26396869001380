import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "../../../Utils/Helper";
import { CLIENT_DETIAL_RESPONSE_TYPES } from "../Types/ResponseTypes";

export const GET_CLIENT_DETAILS = (clientId: string | any) => [
  "clientdetails",
  clientId,
];
const ClientDetails = async (
  clientId: string | null
): Promise<AxiosResponse<CLIENT_DETIAL_RESPONSE_TYPES>> => {
  return fetcher({
    url: `/admin/client/${clientId}`,
    method: "GET",
  });
};

function useClientDetailsQuery(clientId: string | any) {
  return useQuery(GET_CLIENT_DETAILS(clientId), () => ClientDetails(clientId));
}

export default useClientDetailsQuery;
