import { Dispatch, SetStateAction, useState } from "react";
import { Control, RegisterOptions, useController } from "react-hook-form";
import { INPUT_TYPES } from "../../Utils/Types";
import InputText from "../InputText/InputText";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export type FormInputProps = {
  name: string;
  label?: string;
  trigger?: any;
  setValue?: any;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  OutlinedInputProps: any;
  defaultValue?: unknown;
  callingCodeName?: string;
  control?: Control<any>;
  textInputProps?: any;
  setFocus?: (name: any) => void;
  type: INPUT_TYPES;
  textareaProps?: any;
  left?: any;
  leftWidth?: string | number;
  right?: any;
  rightWidth?: string | number;
  JSX?: (_props: Omit<FormInputProps, "JSX">) => any;
  placeholder?: string;
  radioData?: Array<{
    label: string;
    value: string;
  }>;
  checkboxData?: Array<{
    label: string;
    value: string;
  }>;
  min?: number;
  max?: number;
  value?: string;
  step?: number;
  multiSelectInputProps?: any;
  multiSwitchInputProps?: any;
  options?: Array<string>;
  singleSelect?: boolean;
  customCloseIcon?: boolean;
  cropData?: any;
  setCropData?: Dispatch<SetStateAction<File | undefined>>;
  checked?: boolean;
  onChange?: (value: any) => void;
  required?: boolean;
  customClassName?: string;
  inputProps?: any;
  LeftIcon?: () => JSX.Element;
  RightIcon?: () => JSX.Element;
};

function FormInput(props: FormInputProps) {
  const {
    name,
    label,
    rules,
    defaultValue,
    control,
    textInputProps,
    // OutlinedInputProps,
    type,
    JSX,
    // step,
    // callingCodeName = "countryCode",
    left,
    right,
    leftWidth,
    rightWidth,
    // radioData,
    // checkboxData,
    // min,
    // max,
    value,
    // multiSelectInputProps,
    // options,
    // singleSelect,
    // customCloseIcon,
    // cropData,
    // setCropData,
    // checked,

    // multiSwitchInputProps,
    // setValue,
    // trigger,
    // required,
    customClassName,
    // inputProps,
  } = props;
  const { field, formState } = useController({
    name,
    rules,
    defaultValue,
    control,
  });
  const [showPassword, setShowPassword] = useState(false);
  // const [enabled, setEnabled] = useState(false);
  switch (type) {
    case "text": {
      return (
        <InputText
          // OutlinedInputProps={OutlinedInputProps}
          label={label}
          field={field}
          formState={formState}
          left={left}
          right={right}
          leftWidth={leftWidth}
          rightWidth={rightWidth}
          customClassName={customClassName}
        />
      );
    }

    case "email": {
      return (
        <InputText
          label={label}
          field={field}
          formState={formState}
          textInputProps={textInputProps}
          left={left}
          right={right}
        />
      );
    }
    case "number": {
      return (
        <InputText
          label={label}
          field={field}
          formState={formState}
          textInputProps={{
            ...textInputProps,
            type: "number",
          }}
          left={left}
          right={right}
        />
      );
    }
    case "password": {
      return (
        <InputText
          label={label}
          field={field}
          formState={formState}
          value={value}
          // className={'customInputPassword'}

          textInputProps={{
            ...textInputProps,
            type: showPassword ? "text" : "password",
          }}
          left={left}
          RightIcon={() => (
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOff sx={{ color: "text.lightgrey" }} />
              ) : (
                <Visibility sx={{ color: "text.lightgrey" }} />
              )}
            </IconButton>
          )}
        />
      );
    }

    case "custom": {
      return JSX && JSX(props);
    }
  }
}

export default FormInput;
