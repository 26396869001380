import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import AppBar from "../../../../Component/AppBar/AppBar";
import UserMenu from "../../../../Component/UserMenu/UserMenu";
import MenuIcon from "@mui/icons-material/Menu";
import Image from "../../../../Assets/Images/Image";
import { useState } from "react";
import { ROUTES } from "../../../../Navigation/Navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthValue, resetAuthValue } from "../../../AuthModule/Hooks/useAuthValue";
import SnackbarHandler from "../../../../Utils/SnackbarHandler";

function Header({ handleDrawerOpen, open }: any) {
  const [backDropopen, setbackDropopen] = useState(false);
  const navigate = useNavigate();
  const value = getAuthValue()
  const path = useLocation();
  return (
    <AppBar
      color="inherit"
      open={open}
      position="fixed"

    >
      <Toolbar >
        {!open
          ? <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,

              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton> : ""}
        <Box sx={{ marginRight: 4, ...(open && { display: "none" }) }}>
          <img src={Image.logo} alt="" height="48px" width="48px" />
        </Box>
        <Typography variant='body1' sx={{ color: " text.grey " }} fontWeight={500} textTransform={"uppercase"}>
          {path?.pathname?.split("/")?.[1]}
        </Typography>
        <Box display={"flex"} position="absolute" right={3}>
          <UserMenu setbackDropopen={setbackDropopen} />
        </Box>
        <Backdrop open={backDropopen}>
          <Box
            width="25%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            borderRadius={"15px"}
            bgcolor={"#fff"}
            p={4}
          >
            <Typography
              variant="subtitle1"
              fontWeight={700}
              letterSpacing={1}
              color={"black"}
              py={2}
            >
              LOGOUT!
            </Typography>
            <Typography variant="body2" fontWeight={400} color={"black"} py={1}>
              Are you sure you want to logout?
            </Typography>
            <Box
              display="flex"
              py={3}
              flexWrap="nowrap"
              width={"100%"}
              justifyContent={"space-evenly"}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{ borderColor: "black", color: "black" }}
                onClick={() => {
                  setbackDropopen(false);
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ bgcolor: "black" }}
                onClick={() => {
                  resetAuthValue();
                  SnackbarHandler?.successToast("Logged out Successfully!")
                  navigate(ROUTES.LOGIN);
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Backdrop>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
