import { Box, Button, Container, Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getAuthValue, setAuthValue } from "../../Modules/AuthModule/Hooks/useAuthValue";
import { ROUTES } from "../../Navigation/Navigation";
export default function Error() {
  const navigate = useNavigate();
  const error: any = useRouteError();
  console.error(error, "--------------------Error.tsx");
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        display: "flex",
        backgroundColor: "primary.light",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography variant="h1" sx={{ color: "text.primary" }}>
          Oops!
        </Typography>
        <Typography variant="h3">
          Sorry, an unexpected error has occurred.
        </Typography>
        <Typography variant="body1">
          {error.statusText || error.message}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(ROUTES.NEWSLETTER);
            setAuthValue({
              ...getAuthValue(),
              active: "Dashboard"
            })
          }}
        >
          Go Back{" "}
        </Button>
      </Box>
    </Container>
  );
}
