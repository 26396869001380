import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
type backButtonProps = {
  path: any;
  cb?: () => void;
}



function BackButton(props: backButtonProps) {
  const { path, cb } = props
  const navigate = useNavigate()
  return (
    <Button
      onClick={() => {
        navigate(path);
        cb && cb();
      }}
      disableRipple
      sx={{
        position: "absolute",
        top: -30,
        left: 5,
        mr: 0,
        textTransform: "capitalize",
        color: "text.lightgrey",
        ":hover": {
          color: "common.black",
          fontWeight: 600
        }
      }}
    >
      <ArrowBackIosIcon sx={{ height: "13px", width: "13px", mr: 0 }} />
      back
    </Button>
  )
}

export default BackButton