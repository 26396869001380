import { createBrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter";
import Login from "../Modules/AuthModule/Components/Login/Login";
import Error from "../Pages/Error/Error";
import IndustryPage from "../Pages/IndustryPage/IndustryPage";
import DashboardPage from "../Pages/DashboardPage/DashboardPage";
import ClientManagementPage from "../Pages/ClientManagementPage/ClientManagementPage";
import SupplierManagementPage from "../Pages/SupplierManagementPage/SupplierManagementPage";
import SupplierPurchaseHistory from "../Modules/SupplierModule/Component/SupplierPurchaseHistory/SupplierPurchaseHistory";
import SupplierGigs from "../Modules/SupplierModule/Component/SupplierGigs/SupplierGigs";
import ClientProposal from "../Modules/ClientModule/Component/ClientProposal/ClientProposal";
import AuthRouter from "./AuthRouter";
import ProposalDeatails from "../Modules/ClientModule/Component/ProposalDeatails/ProposalDeatails";
import GigManagementPage from "../Pages/GigManagementPage/GigManagementPage";
import ClientGigsDeatils from "../Modules/ClientModule/Component/ClientGigsDeatils/ClientGigsDeatils";
import SupplierGigDetails from "../Modules/SupplierModule/Component/SupplierGigsDetails/SupplierGigsDetails";
import SupplierProposal from "../Modules/SupplierModule/Component/SupplierProposal/SupplierProposal";
import ClientDeatils from "../Modules/ClientModule/Component/ClientDetails/ClientDeatils";
import UnderDevelopmentPage from "../Pages/UnderDevelopmentPage/UnderDevelopmentPage";
import ServicePage from "../Pages/ServicePage/ServicePage";
import SupplierProfile from "../Modules/SupplierModule/Component/SupplierProfile/SupplierProfilePage";
import NewsLetterPage from "../Pages/NewsLetterPage/NewsLetterPage";
import SubscriptionMangementpage from "../Pages/SubscriptionMangementpage/SubscriptionMangementpage";
import ChangePasswordPage from "../Pages/ChangePasswordPage/ChangePasswordPage";
// import SubscriptionMangementpage from "../Pages/SubscriptionMangementpage/SubscriptionMangementpage";
export const ROUTES = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  CLIENTMANAGEMENT: "/client-management",
  CLIENTDETAILS: "/client-management/clientDetails/:id",
  CLIENTGIGDETAILS: "/client-management/client-gigdetail/:id",
  INDUSTRY: "/industrytype-management",
  SUPPLIERMANAGEMENT: "/supplier-management",
  SUPPLIERMANAGEMENTFILTER:
    "/supplier-management/:filterType/:status/:value/:startDate/:endDate",
  SUPPLIERPROFILE: "/supplier-management/supplier-profile/:id",
  SUPPLIERPURCHASEHISTORY: "/supplier-management/purchase-history",
  SUPPLIERGIG: "/supplier-management/gigs",
  SUPPLIERGIGDETAIL: "/supplier-management/gigs-Detail/:id",
  SUPPLIERPROPOSAL: "/supplier-management/supplier-proposal/:gigId/:supplierId",
  CLIENTPROPOSALS: "/client-management/proposal/:id",
  CLIENTPROPOSALDETAIL: "/client-management/proposal-details/:id",
  GIGSMANAGEMENT: "/gig-management",
  GIGDETAIL: "/gig-management/detail/:id",
  GIGPROPOSAL: "/gig-management/proposal/:id",
  GIGPROPOSALDETAIL: "/gig-management/proposal-details/:id",
  SUBSCRIPTONMANAGEMENT: "/subscription-management",
  SERVICE: "/Service",
  UNDERDEVELOPMENT: "/underdevelopment",
  CLIENTMANAGEMENTILTER: "/client-management",
  NEWSLETTER: "/newsletter",
  CHANGEPASSWORD: "/Change-Password",
};

const navigationRouter = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: (
      <AuthRouter>
        <Login />
      </AuthRouter>
    ),
    errorElement: <Error />,
  },
  // {
  //   path: ROUTES.DASHBOARD,
  //   element: (
  //     <AppRouter>
  //       <DashboardPage />
  //     </AppRouter>
  //   ),
  // },

  {
    path: ROUTES.CLIENTMANAGEMENT,
    element: (
      <AppRouter>
        <ClientManagementPage />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.CLIENTMANAGEMENTILTER,
    element: (
      <AppRouter>
        <ClientManagementPage />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.CLIENTDETAILS,
    element: (
      <AppRouter>
        <ClientDeatils />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.CLIENTGIGDETAILS,
    element: (
      <AppRouter>
        <ClientGigsDeatils />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.CLIENTPROPOSALS,
    element: (
      <AppRouter>
        <ClientProposal />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.CLIENTPROPOSALDETAIL,
    element: (
      <AppRouter>
        <ProposalDeatails />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.SUPPLIERMANAGEMENT,
    element: (
      <AppRouter>
        <SupplierManagementPage />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.SUPPLIERMANAGEMENTFILTER,
    element: (
      <AppRouter>
        <SupplierManagementPage />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.SUPPLIERPROFILE,
    element: (
      <AppRouter>
        <SupplierProfile />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.SUPPLIERPURCHASEHISTORY,
    element: (
      <AppRouter>
        <SupplierPurchaseHistory />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.SUPPLIERGIG,
    element: (
      <AppRouter>
        <SupplierGigs />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.SUPPLIERGIGDETAIL,
    element: (
      <AppRouter>
        <SupplierGigDetails />
      </AppRouter>
    ),
  },
  {
    path: ROUTES.SUPPLIERPROPOSAL,
    element: (
      <AppRouter>
        <SupplierProposal />
      </AppRouter>
    ),
  },

  // {
  //   path: ROUTES.INDUSTRY,
  //   element: (
  //     <AppRouter>
  //       <IndustryPage />
  //     </AppRouter>
  //   ),
  // },
  // {
  //   path: ROUTES.GIGSMANAGEMENT,
  //   element: (
  //     <AppRouter>
  //       <GigManagementPage />
  //     </AppRouter>
  //   ),
  // },

  // {
  //   path: ROUTES.GIGDETAIL,
  //   element: (
  //     <AppRouter>
  //       <ClientGigsDeatils />
  //     </AppRouter>
  //   ),
  // },
  // {
  //   path: ROUTES.GIGPROPOSAL,
  //   element: (
  //     <AppRouter>
  //       <ClientProposal />
  //     </AppRouter>
  //   ),
  // },
  // {
  //   path: ROUTES.GIGPROPOSALDETAIL,
  //   element: (
  //     <AppRouter>
  //       <ProposalDeatails />
  //     </AppRouter>
  //   ),
  // },

  // {
  //   path: ROUTES.SUBSCRIPTONMANAGEMENT,
  //   element: (
  //     <AppRouter>
  //       <SubscriptionMangementpage />
  //     </AppRouter>
  //   ),
  // },
  // {
  //   path: ROUTES.SERVICE,
  //   element: (
  //     <AppRouter>
  //       <ServicePage />
  //     </AppRouter>
  //   ),
  // },
  {
    path: ROUTES.NEWSLETTER,
    element: (
      <AppRouter>
        <NewsLetterPage />
      </AppRouter>
    ),
  },
  // {
  //   path: ROUTES.CHANGEPASSWORD,
  //   element: (
  //     <AppRouter>
  //       <ChangePasswordPage />
  //     </AppRouter>
  //   ),
  // },
  // {
  //   path: ROUTES.UNDERDEVELOPMENT,
  //   element: (
  //     <AppRouter>
  //       <UnderDevelopmentPage />
  //     </AppRouter>
  //   ),
  // },
]);

export default navigationRouter;
