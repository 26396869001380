import { Box, Skeleton } from "@mui/material";

const ProposalSkeletonLoader = ({ number }: any) => {
    const skeletonArray: Array<any> = Array(number).fill("");
    return number && skeletonArray.map((item: any, index: number) => (
        <Box
            width={"100%"}
            key={index}
            border="1px solid "
            borderColor="lightgrey"
            p={2}
            sx={{
                background:
                    "  linear-gradient(252.46deg, rgba(2, 152, 157, 0.1) 0%, rgba(94, 23, 235, 0.1) 100%) ,   linear-gradient(247.68deg, rgba(244, 245, 255, 0.25) 9.3%, rgba(206, 247, 241, 0.25) 85.31%)",
            }}
            borderRadius={"8px"}
            my={2}
        >
            <Box
                display="flex"
                alignItems="center"
                width={"100%"}
                justifyContent="space-between"
                py={1}
            >
                <Box display="flex" alignItems="center" width={"100%"}>
                    <Skeleton
                        animation="pulse"
                        variant="circular"
                        height="45px"
                        width="45px"
                    />
                    <Skeleton
                        animation="pulse"
                        variant="text"
                        width="200px"
                        height="20px"
                        sx={{ mx: 2 }}
                    />
                    <Skeleton
                        animation="pulse"
                        variant="text"
                        width="50px"
                        height="20px"
                        sx={{ mx: 2 }}
                    />
                </Box>
                <Skeleton
                    animation="pulse"
                    variant="text"
                    width="100px"
                    height="30px"
                    sx={{ mx: 2, float: "right  " }}
                />
            </Box>
            <Skeleton
                animation="pulse"
                variant="text"
                width="200px"
                height="20px"
                sx={{ my: 1 }}
            />
            <Skeleton
                animation="pulse"
                variant="rounded"
                width={"100%"}
                height={60}
            />
        </Box>
    ));
};

export default ProposalSkeletonLoader;
