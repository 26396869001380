import Layout from "../../../LayoutModule/Components/Layout";
import { Avatar, Box, Chip, Divider, Typography } from "@mui/material";
import BackButton from "../../../../Component/BackButton/BackButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Done,
  Group,
  Language,
  LocationCity,
  LocationOn,
  Person,
  Work,
} from "@mui/icons-material";
import { Rating } from "react-simple-star-rating";
import useSupplierProfileQuery from "../../../GigModule/Hooks/useSupplierProfileQuery";
import { useParams } from "react-router-dom";
import SupplierProfileSidebar from "../../../../Component/SupplierProfileSidebar/SupplierProfileSidebar";
import ProposalDescription from "../../../../Component/ProposalDescription/ProposalDescription";

function ProposalDeatails() {
  const { id: proposalId } = useParams();
  const { data } = useSupplierProfileQuery(proposalId);
  const supplier = data?.data?.data;
  const { address, city, country, state, zipCode } =
    supplier?.supplier_data?.address ?? {};
  const toDataURL = async (url: any) => {
    return await fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return window.URL.createObjectURL(blob);
      });
  };
  const downloadAttachment = async (url: any) => {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = url.replace(/^.*[\\/]/, "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const downloadAllAttachments = () => {
    if (supplier?.attachments && supplier?.attachments.length > 0) {
      supplier?.attachments.forEach((attachment) => {
        downloadAttachment(attachment);
      });
    } else {
      console.warn("No attachments found.");
    }
  };
  return (
    <Layout>
      <Box position="relative">
        <BackButton path={-1} />

        <Box p={6} bgcolor={"#F2FAFA"} borderRadius={"15px"}>
          <Box display="flex" alignItems="center">
            <Avatar
              src={supplier?.supplier_data?.companyLogo}
              sx={{ width: "130px", height: "130px", objectFit: "fit" }}
            />
            <Box ml={2} width="100%">
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent={"space-between"}
              >
                <Typography
                  variant="h5"
                  color="common.black"
                  py={1}
                  fontWeight={600}
                >
                  {supplier?.supplier_data?.companyName
                    ? supplier?.supplier_data?.companyName
                    : "Not Available"}
                </Typography>
                <Chip
                  icon={<Done color="success" />}
                  label="Verified"
                  size="medium"
                  sx={{ bgcolor: "#C3FFD8", color: "green", mx: 7 }}
                />
              </Box>

              <Rating initialValue={4.5} size={30} readonly />
              <Box display="flex" alignItems={"center"} py={1} px={0.4}>
                <MailOutlineIcon fontSize="small" sx={{ color: "text.grey" }} />
                <Typography
                  color="text.grey"
                  fontSize={13}
                  textAlign="left"
                  px={1}
                >
                  {supplier?.supplier_data?.email
                    ? supplier?.supplier_data?.email
                    : "Not Available"}
                </Typography>
                <LocationOn
                  fontSize="small"
                  sx={{ color: "text.grey", ml: 4 }}
                />
                <Typography
                  color="text.grey"
                  fontSize={13}
                  textAlign="left"
                  px={1}
                >
                  {country}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <ProposalDescription
          similarWork={
            supplier?.similarWork ? supplier?.similarWork : "Not Available"
          }
          reason={supplier?.reason ? supplier?.reason : "Not Available"}
          downloadAllAttachments={downloadAllAttachments}
        />

        <Box
          width={"30%"}
          // minHeight={"665px"}
          border={"1px solid "}
          borderColor={"lightGrey"}
          p={2}
          borderRadius={"15px"}
          position={"absolute"}
          top={110}
          right={80}
          bgcolor={"#fff"}
          zIndex={1}
          marginBottom={5}
          // overflow={"hidden"}
        >
          <SupplierProfileSidebar
            label="Company Name"
            icon={<LocationCity fontSize="small" sx={{ color: "text.grey" }} />}
            content={
              supplier?.supplier_data?.companyName
                ? supplier?.supplier_data?.companyName
                : "Not Available"
            }
          />

          <SupplierProfileSidebar
            label="Website"
            icon={<Language fontSize="small" sx={{ color: "text.grey" }} />}
            content={
              supplier?.supplier_data?.companyName
                ? supplier?.supplier_data?.companyName
                : "Not Available"
            }
          />

          <SupplierProfileSidebar
            label="Experience"
            icon={<Work fontSize="small" sx={{ color: "text.grey" }} />}
            content={`${
              supplier?.experience
                ? `${supplier?.experience} years Experience`
                : "Not Available"
            }`}
          />

          <SupplierProfileSidebar
            label="Team Size"
            icon={<Group fontSize="small" sx={{ color: "text.grey" }} />}
            content={`${
              supplier?.teamSize ? supplier?.teamSize : "Not Available"
            } employees`}
          />

          <Box width={"95%"} py={1}>
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"flex-start"}
              py={1}
              px={0.4}
            >
              <Person fontSize="small" sx={{ color: "text.grey" }} />
              <Typography
                color="text.lightgrey"
                fontWeight={600}
                fontSize={18}
                textAlign="left"
                px={1}
              >
                Skills
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={1}
              alignItems={"center"}
              px={1}
              pb={1}
              flexWrap={"wrap"}
            >
              {supplier?.skills?.length !== 0 ? (
                supplier?.skills?.map((item) => (
                  <Typography
                    color="text.lightgrey"
                    fontSize={15}
                    textAlign="left"
                    bgcolor={"#D9D9D9"}
                    borderRadius={"5px"}
                    p={0.5}
                  >
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography
                  color="text.lightgrey"
                  fontSize={15}
                  textAlign="left"
                  borderRadius={"5px"}
                  p={0.5}
                >
                  Not Available
                </Typography>
              )}
            </Box>
            <Divider />
          </Box>
          <SupplierProfileSidebar
            label="Address"
            icon={<LocationOn fontSize="small" sx={{ color: "text.grey" }} />}
            content={`${address},${city}, ${state},${country},${zipCode}`}
          />
        </Box>
      </Box>
    </Layout>
  );
}

export default ProposalDeatails;
