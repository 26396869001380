import { Box, Typography, Button } from "@mui/material";
import React from "react";

type proposalDescriptionProps = {
  reason: string | undefined;
  similarWork: string | undefined;
  downloadAllAttachments: () => void;
};
function ProposalDescription(props: proposalDescriptionProps) {
  const { reason, similarWork, downloadAllAttachments } = props;
  return (
    <Box width={"60%"} pl={2} py={2}>
      <Box minHeight={"30vh"} py={2}>
        <Typography
          py={1}
          variant="h5"
          fontWeight={600}
          fontSize={22}
          sx={{ color: "text.lightgrey" }}
        >
          Why you should choose us?
        </Typography>
        <Typography
          variant="body1"
          fontWeight={500}
          fontSize={18}
          sx={{ color: "text.lightgrey" }}
        >
          {reason}.
        </Typography>
      </Box>
      <Box minHeight={"30vh"}>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent={"space-between"}
          pt={3}
        >
          <Typography
            variant="h5"
            color="text.lightgrey"
            py={1}
            fontWeight={600}
          >
            Similar Work Done
          </Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={downloadAllAttachments}
          >
            Download Attachments
          </Button>
        </Box>
        <Typography
          variant="body1"
          fontWeight={500}
          fontSize={18}
          sx={{ color: "text.lightgrey" }}
          py={1}
        >
          {similarWork}
        </Typography>
      </Box>
    </Box>
  );
}

export default ProposalDescription;
