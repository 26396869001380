import { fetcher } from "../../../Utils/Helper";
import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import SnackbarHandler from "../../../Utils/SnackbarHandler";
import { useNavigate } from "react-router-dom";

const deleteGig = async (gigId: any): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: `/admin/gigs/${gigId}`,
    method: "DELETE",
    // data: removeKey(data),
  });
};
function useDeleteGigMutation() {
  const navigate = useNavigate();
  return useMutation(deleteGig, {
    onSuccess: (res) => {
      SnackbarHandler.successToast(res?.data?.message);
      navigate(-1);
    },
  });
}
export default useDeleteGigMutation;
