import {
  Done,
  LocationOn,
  LocationCity,
  Language,
  Work,
  Group,
  Person,
} from "@mui/icons-material";
import { Box, Typography, Chip, Divider } from "@mui/material";
import BackButton from "../../../../Component/BackButton/BackButton";
import Layout from "../../../LayoutModule/Components/Layout";
import { useParams } from "react-router-dom";
import useGigProposalsQuery from "../../../GigModule/Hooks/useGigProposalsQuery";
import CloseIcon from "@mui/icons-material/Close";
import SupplierProfileSidebar from "../../../../Component/SupplierProfileSidebar/SupplierProfileSidebar";
import ProposalDescription from "../../../../Component/ProposalDescription/ProposalDescription";
export default function SupplierProposal() {
  const { gigId, supplierId } = useParams();
  const { data } = useGigProposalsQuery(gigId, 1, supplierId);
  const supplierProposal = data?.data?.data;
  const { address, city, state, country, zipCode }: any =
    supplierProposal?.list[0]?.supplier_data?.address ?? {};
  const toDataURL = async (url: any) => {
    return await fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return window.URL.createObjectURL(blob);
      });
  };
  const downloadAttachment = async (url: any) => {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = url.replace(/^.*[\\\/]/, "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const downloadAllAttachments = () => {
    if (
      supplierProposal?.list[0]?.attachments &&
      supplierProposal?.list[0]?.attachments.length > 0
    ) {
      supplierProposal?.list[0]?.attachments.forEach((attachment) => {
        downloadAttachment(attachment);
      });
    } else {
      console.warn("No attachments found.");
    }
  };
  return (
    <Layout>
      <Box position="relative">
        <BackButton path={-1} />
        <Typography
          variant="h4"
          color={"black"}
          fontWeight={600}
          px={2}
          py={3}
          width={"60%"}
        >
          {supplierProposal?.gigTitle}{" "}
        </Typography>
        {supplierProposal?.list[0]?.status !== 1 &&
        supplierProposal?.list[0]?.status !== 2 ? (
          <Chip
            icon={
              supplierProposal?.list[0]?.status === 3 ? (
                <Done color="success" />
              ) : (
                <CloseIcon color="error" />
              )
            }
            label={
              supplierProposal?.list[0]?.status === 3
                ? "Proposal Accepted"
                : "Proposal Rejected"
            }
            variant="outlined"
            color={
              supplierProposal?.list[0]?.status === 3 ? "success" : "error"
            }
            size="medium"
            sx={{
              bgcolor:
                supplierProposal?.list[0]?.status === 3 ? "#C3FFD8" : "#FFCCCB",
              color: supplierProposal?.list[0]?.status === 3 ? "green" : "red",
              mx: 3,
            }}
          />
        ) : (
          ""
        )}

        <ProposalDescription
          similarWork={supplierProposal?.list[0]?.similarWork}
          reason={supplierProposal?.list[0]?.reason}
          downloadAllAttachments={downloadAllAttachments}
        />
        <Box
          width={"30%"}
          minHeight={"665px"}
          border={"1px solid "}
          borderColor={"lightGrey"}
          p={2}
          borderRadius={"15px"}
          position={"absolute"}
          top={60}
          right={100}
          bgcolor={"#fff"}
          zIndex={1}
        >
          <SupplierProfileSidebar
            label="Company Name"
            icon={<LocationCity fontSize="small" sx={{ color: "text.grey" }} />}
            content={supplierProposal?.list[0]?.supplier_data?.companyName}
          />

          <SupplierProfileSidebar
            label="Website"
            icon={<Language fontSize="small" sx={{ color: "text.grey" }} />}
            content={supplierProposal?.list[0]?.supplier_data?.companyWebsite.substring(
              8,
              25
            )}
          />

          <SupplierProfileSidebar
            label="Experience"
            icon={<Work fontSize="small" sx={{ color: "text.grey" }} />}
            content={`${supplierProposal?.list[0]?.experience} years Experience`}
          />

          <SupplierProfileSidebar
            label="Team Size"
            icon={<Group fontSize="small" sx={{ color: "text.grey" }} />}
            content={`${supplierProposal?.list[0]?.teamSize} employees`}
          />
          <Box width={"95%"} py={2}>
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"flex-start"}
              py={1}
              px={0.4}
            >
              <Person fontSize="small" sx={{ color: "text.grey" }} />
              <Typography
                color="text.lightgrey"
                fontWeight={600}
                fontSize={18}
                textAlign="left"
                px={1}
              >
                Skills
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={1}
              alignItems={"center"}
              px={1}
              pb={1}
              flexWrap={"wrap"}
            >
              {supplierProposal?.list[0]?.skills?.map((item) => (
                <Typography
                  color="black"
                  fontSize={15}
                  textAlign="left"
                  bgcolor={"#D9D9D9"}
                  borderRadius={"5px"}
                  p={1}
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Divider />
          </Box>

          <SupplierProfileSidebar
            label="Address"
            icon={<LocationOn fontSize="small" sx={{ color: "text.grey" }} />}
            content={`${address},${city}, ${state},${country},${zipCode}`}
          />
        </Box>
      </Box>
    </Layout>
  );
}
