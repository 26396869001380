import { Skeleton, TableCell, TableRow } from "@mui/material";
type tableSkeletonProps = {
    rows: number,
    column: number
}
function TableSkeletonLoader(props: tableSkeletonProps) {
    const { rows, column } = props
    const rowsSkelton = Array(rows).fill('')
    const columnSkeleton = Array(column).fill('')
    return (<>
        {rows && rowsSkelton?.map((item, index) => <TableRow key={index}>
            {column && columnSkeleton?.map((item, index) => <TableCell component="th" scope="row" key={index}>
                <Skeleton variant="text" sx={{ py: 2, bgcolor: "lightgrey" }} animation="pulse" />
            </TableCell>)}


        </TableRow>)}
    </>
    )
};
export default TableSkeletonLoader;