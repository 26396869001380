import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { List, ListItemButton, Typography, Collapse } from '@mui/material'
import React, { useState } from 'react'
import useIndustryQuery from '../../Modules/IndustryModule/Hooks/useIndustryQuery';
import { INDUSTRY_LIST_ITEM } from '../../Modules/IndustryModule/Types/ResponseTypes';
import Checkbox from '@mui/material/Checkbox';
type industryTypeListProps = {
    Item: any,
    checkedItems: Array<string> | any,
    setchekedItems: React.Dispatch<React.SetStateAction<Array<string>>> | any
}
function IndustryTypeList(props: industryTypeListProps) {
    const { Item, checkedItems, setchekedItems } = props
    const [open, setOpen] = useState(false);
    const [page, setpage] = useState(1);
    const searchQuery = "";
    const limit = 100
    const handleClick = () => {
        setOpen(!open);
    };
    const { data } = useIndustryQuery(page, searchQuery, limit)
    const industries = data?.data?.data?.industries ?? [];
    // const [checkedItems, setchekedItems] = useState<Array<string>>([])
    return (
        <List sx={{ p: 0 }}>
            <ListItemButton onClick={handleClick} sx={{ p: 0 , width:"80%" }}>

                <Typography color={"black"} textTransform={"capitalize"}> {Item.text}</Typography>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>


                {industries?.map((item: INDUSTRY_LIST_ITEM) =>
                    <List disablePadding>
                        <ListItemButton  >
                            <Checkbox
                                checked={checkedItems.includes(item?.industryType)}
                                value={item?.industryType}

                                onClick={() => {
                                    const data: Array<string> = [...checkedItems];
                                    if (checkedItems.includes(item?.industryType)) {
                                        data.splice(data.indexOf(item?.industryType), 1)
                                    } else {
                                        data.push(item?.industryType);
                                    }
                                    setchekedItems(data);
                                }}
                            />
                            <Typography color={"black"}> {item.industryType}</Typography>

                        </ListItemButton>
                    </List>
                )}


            </Collapse>
        </List>
    )
}

export default IndustryTypeList