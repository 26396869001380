import axios, { AxiosRequestConfig } from "axios";
import { getAuthValue } from "../Modules/AuthModule/Hooks/useAuthValue";
import SnackbarHandler from "./SnackbarHandler";
import AuthService from "../Modules/AuthModule/Services/AuthServices";

export const fetcher = (config: AxiosRequestConfig) => {
  const { url, method = "GET", data, headers } = config;
  const { token } = getAuthValue();
  return axios.request({
    baseURL: process.env.REACT_APP_API_KEY,
    // baseURL: `http://40.172.5.111:3000/`,
    url,
    method,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    ...config,
  });
};

export const onError = (error: any) => {
  if (error?.response) {
    console.log({ error: error?.response });
    if (error?.response?.status === 401) {
      AuthService.resetAuthValue();
      // SnackbarHandler.errorToast("Session Expired");
    }

    if (error?.response?.status !== 404) {
      SnackbarHandler.errorToast(error?.response?.data?.message);
    }
  } else {
    console.log({ error });
  }
};

export const removeKey = (obj: any) => {
  const extractData: any = Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => {
      if (v !== undefined) {
        return v;
      }
    })
  );
  return extractData;
};
