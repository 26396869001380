import { useState } from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Navigation/Navigation";
import FormInput from "../../../../Component/FormInput/FormInput";
import { useForm } from "react-hook-form";
import { LOGIN_REQUEST } from "../../Types/RequestTypes";
import useLoginMutation from "../../Hooks/useLoginMutation";
import Image from "../../../../Assets/Images/Image";
import { getRequiredRules } from "../../../../Utils/validator";
import { getAuthValue, setAuthValue } from "../../Hooks/useAuthValue";
import SnackbarHandler from "../../../../Utils/SnackbarHandler";

export default function Login() {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);

  const { handleSubmit, control } = useForm<LOGIN_REQUEST>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { mutate } = useLoginMutation();
  const handleLogin = handleSubmit((value) => {
    mutate(
      {
        email: value.email,
        password: value.password,
      },
      {
        onSuccess: (response: any) => {
          const { status, data } = response?.data;
          if (status === 200 || 201) {
            setAuthValue({
              ...getAuthValue,
              email: data?.email,
              loggedIn: true,
              token: data?.token,
              // active: "Dashboard",
              active: "NewsLetter",
            });
            SnackbarHandler.successToast(response?.data?.message);

            // navigate(ROUTES.DASHBOARD);
            navigate(ROUTES.NEWSLETTER);
          }
        },
      }
    );
  });

  return (
    <Container
      disableGutters
      maxWidth={false}
      className="container"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        background:
          " linear-gradient(252.46deg, rgba(2, 152, 157, 0.1) 0%, rgba(94, 23, 235, 0.1) 100%),linear-gradient(247.68deg, rgba(244, 245, 255, 0.25) 9.3%, rgba(206, 247, 241, 0.25) 85.31%)",
      }}
    >
      <Box position="absolute" top={40} left={60}>
        <img src={Image.logo} alt="Connect If" />
      </Box>
      <Paper
        elevation={3}
        sx={{
          width: { lg: "25%", md: "35%", sm: "50%", xs: "90%" },

          p: {
            lg: 4,
            md: 4,
            sm: 2,
            xs: 1,
          },
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          borderRadius: 2,
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={700}
          my={1}
          color={"text.primary"}
          letterSpacing={0.5}
        >
          LogIn
        </Typography>

        <form onSubmit={handleLogin} style={{ width: "100%" }}>
          <Typography
            color="text.lightgrey"
            textAlign="left"
            fontSize="12px"
            lineHeight="10px"
            fontWeight={400}
            mb={1}
            mt={2}
          >
            Email
          </Typography>
          <FormInput
            name="email"
            control={control}
            type="email"
            placeholder="Email"
            rules={getRequiredRules("email")}
            OutlinedInputProps={{
              my: 1,
              color: "text.ligthgrey",
            }}
          />
          <Typography
            color="text.lightgrey"
            textAlign="left"
            fontSize="12px"
            lineHeight="10px"
            fontWeight={400}
            mb={1}
            mt={2}
          >
            Password
          </Typography>
          <FormInput
            control={control}
            name="password"
            OutlinedInputProps={{ my: 1, color: "primary " }}
            type={showPass ? "text" : "password"}
            rules={getRequiredRules("password")}
            customClassName="input-focus"
            placeholder="Password"
            RightIcon={() => (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPass(!showPass)}
                edge="end"
              >
                {showPass ? (
                  <VisibilityOff sx={{ color: "text.lightgrey" }} />
                ) : (
                  <Visibility sx={{ color: "text.lightgrey" }} />
                )}
              </IconButton>
            )}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            sx={{
              bgcolor: "#5E17EB",
              marginTop: 2,
              borderRadius: 5,
            }}
          >
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
