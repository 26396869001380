import { AxiosResponse } from "axios";
import { SUPPLIER_LIST_REQUEST_TYPE } from "../Types/RequestTypes";
import { fetcher } from "../../../Utils/Helper";
import { useMutation } from "react-query";
import { queryClient } from "../../../Utils/ReactQueryConfig";

const updateSupplier = async (
  data: SUPPLIER_LIST_REQUEST_TYPE
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: "/admin/supplier",
    method: "POST",
    data: data,
  });
};
function useSupplierMutation() {
  return useMutation(updateSupplier, {
    onSuccess: (response) => {
      if (response) {
        queryClient.refetchQueries();
      }
    },
  });
}
export default useSupplierMutation;
