import { LocationOn } from "@mui/icons-material";
import { Box, Typography, Divider } from "@mui/material";
import React from "react";
type supplierProfileSideBarProps = {
  icon: JSX.Element;
  label: string;
  content: any;
};
function SupplierProfileSidebar(props: supplierProfileSideBarProps) {
  const { icon, label, content } = props;
  return (
    <>
      <Box width={"95%"} py={1}>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-start"}
          py={1}
          px={0.4}
        >
          {icon}
          <Typography
            color="text.lightgrey"
            fontWeight={600}
            fontSize={18}
            textAlign="left"
            px={1}
          >
            {label}
          </Typography>
        </Box>
        <Typography
          color="text.lightgrey"
          fontSize={15}
          textAlign="left"
          px={1}
          pb={1}
        >
          {content}
        </Typography>
        <Divider />
      </Box>
    </>
  );
}

export default SupplierProfileSidebar;
