import { Avatar, Box, Button, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useLocation, useNavigate } from "react-router-dom";
type clientProposalCard = {
  src: string;
  companyName: string;
  rating: number;
  path: any;
  reason: string;
};
function ClientProposalCard(props: clientProposalCard) {
  const { src, companyName, rating, path, reason } = props;
  const navigate = useNavigate();

  return (
    <Box
      width={"100%"}
      border="1px solid "
      borderColor="lightgrey"
      p={2}
      sx={{
        background:
          "  linear-gradient(252.46deg, rgba(2, 152, 157, 0.1) 0%, rgba(94, 23, 235, 0.1) 100%) ,   linear-gradient(247.68deg, rgba(244, 245, 255, 0.25) 9.3%, rgba(206, 247, 241, 0.25) 85.31%)",
      }}
      borderRadius={"8px"}
      my={2}
    >
      <Box
        display="flex"
        alignItems="center"
        width={"100%"}
        justifyContent="space-between"
        py={1}
      >
        <Box display="flex" alignItems="center">
          <Avatar
            src={src}
            alt="comapny logo"
            sx={{ height: "45px", width: "45px" }}
          />
          <Typography fontWeight={600} fontSize={16} px={1} color={"black"}>
            {companyName}
          </Typography>

          <StarIcon
            sx={{ height: "20px", width: "20px", color: "#FEC103", ml: 5 }}
          />
          <Typography
            fontWeight={500}
            fontSize={14}
            px={0.5}
            sx={{ color: "text.lightgrey" }}
          >
            <b style={{ color: "black" }}>{rating}</b>(1k+)
          </Typography>
        </Box>

        <Button variant="outlined" size="medium" onClick={() => navigate(path)}>
          View Proposal
        </Button>
      </Box>
      <Typography
        py={1}
        variant="subtitle1"
        fontWeight={600}
        fontSize={15}
        sx={{ color: "text.lightgrey" }}
      >
        Why you should choose us?
      </Typography>
      <Typography
        variant="body1"
        fontWeight={500}
        fontSize={14}
        sx={{ color: "text.lightgrey" }}
      >
        {reason}
      </Typography>
    </Box>
  );
}

export default ClientProposalCard;
