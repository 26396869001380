import React from "react";
import Layout from "../../Modules/LayoutModule/Components/Layout";
import SupplierMangement from "../../Modules/SupplierModule/Component/SupplierManagement/SupplierMangement";

function SupplierManagementPage() {
  return (
    <Layout>
      <SupplierMangement />
    </Layout>
  );
}

export default SupplierManagementPage;
