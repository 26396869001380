import useObservableValue from "../../../Hooks/useObservableValue";
import AuthServices from "../Services/AuthServices";
import { AUTH_STATE } from "../Types/CommonTypes";

function useAuthValue(): AUTH_STATE {
  return useObservableValue(AuthServices.authState$);
}

export default useAuthValue;

export const getAuthValue = () => AuthServices.authState$.getValue();

export const setAuthValue = (authState: AUTH_STATE) =>
  AuthServices.authState$.next(authState);

export const resetAuthValue = () => AuthServices.resetAuthValue();
