import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "../../../Utils/Helper";
import { SUPPLIER_GIGDETAILS_RESPONSE_TYPES } from "../Types/ResponseTypes";

export const GET_SUPLIERGIG_LIST = (
  search: string,
  page: number,
  userId: string | null,
  userFilter: string,
  status?: number,
  industryType?: Array<string>
) => ["SupplierGig", search, page, userId, userFilter, status, industryType];

const SupplierGigList = async (
  search: string,
  page: number,
  userId: string | null,
  userFilter: string,
  status?: number,
  industryType?: Array<string>
): Promise<AxiosResponse<SUPPLIER_GIGDETAILS_RESPONSE_TYPES>> => {
  return fetcher({
    url: `/admin/gigs?search=${search}&page=${page}&userId=${userId}&userFilter=${userFilter}&status=${status}&industryType=${industryType}&limit=10`,
    method: "GET",
  });
};

function useSupplierGigsQuery(
  search: string,
  page: number,
  userId: string | null,
  userFilter: string,
  status?: number,
  industryType?: Array<string>
) {
  return useQuery(
    GET_SUPLIERGIG_LIST(search, page, userId, userFilter, status, industryType),
    () =>
      SupplierGigList(search, page, userId, userFilter, status, industryType)
  );
}

export default useSupplierGigsQuery;
