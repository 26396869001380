import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const ToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "rgba(0,0,0,0.5)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(0,0,0,0.5)",
        height: 22,
        fontSize: 13,
        p: 2,
        textAlign: "center",
        display: "flex",
        alignItems: "center"
    },
}));

export default ToolTip