import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "../../../Utils/Helper";
import { CLIENT_GIG_DESCRIPTION_TYPES } from "../Types/ResponseTypes";

export const GET_CLIENTGIG_LIST = (id: string | any) => [
  "clientGigDecription",
  id,
];

const ClientGigDescription = async (
  id: string | any
): Promise<AxiosResponse<CLIENT_GIG_DESCRIPTION_TYPES>> => {
  return fetcher({
    url: `/admin/gigs/detail/${id}`,
    method: "GET",
  });
};

function useClientGigDescriptionQuery(id: string | any) {
  return useQuery(GET_CLIENTGIG_LIST(id), () => ClientGigDescription(id));
}

export default useClientGigDescriptionQuery;
