import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Typography } from "@mui/material";
import IndustryTypeList from "../IndustryTypeList/IndustryTypeList";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Navigation/Navigation";
import { queryClient } from "../../Utils/ReactQueryConfig";
import { GET_DASHBOARD_LIST } from "../../Modules/DashboardModule/Hooks/useDashBoardQuery";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 0,
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: -30,
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    maxHeight: " 350px",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "black",
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
type dropdownProps = {
  title: string;
  menu: Array<any>;
  setStatus: Dispatch<SetStateAction<any>>;
  icon: JSX.Element;
  checkedItems?: Array<string> | any;
  setchekedItems?: React.Dispatch<React.SetStateAction<Array<string>>> | any;
  backgroundColor: string;
  color: string;
  variant: "contained" | "outlined" | "text";
  filterData?: any;
  setFilter?: any;
  filter?: any;
};
var currentYear = new Date().getFullYear()?.toString();
export default function DropdownButton(props: dropdownProps) {
  const {
    title,
    menu,
    setStatus,
    icon,
    checkedItems,
    setchekedItems,
    backgroundColor,
    color,
    variant,
    filterData,
    setFilter,
    filter,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  return (
    <div>
      <Button
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant={variant}
        sx={{
          bgcolor: backgroundColor,
          textTransform: "capitalize",
          color: color,

          fontSize: {
            lg: 12,
            md: 12,
            sm: 8,
            xs: 8,
          },
          borderColor: "#C7C7C7",
          ":hover": {
            bgcolor: backgroundColor,
            color: color,
            borderColor: "#C7C7C7",
          },
        }}
        disableElevation
        onClick={handleClick}
        endIcon={icon}
        size="medium"
      >
        {title}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menu &&
          menu.map((item: any, index: any) => (
            <MenuItem
              onClick={() => {
                item?.filter
                  ? setStatus({
                      ...filterData,
                      filterStatus: item?.filter,
                      selectedYear: currentYear,
                      selectedMonth: "01",
                      filterType: item?.filter === "today" ? true : false,
                    })
                  : item?.year
                  ? setStatus({
                      ...filterData,
                      selectedYear:
                        item?.year === currentYear ? currentYear : item?.year,
                      filterType: false,
                    })
                  : item?.month
                  ? setStatus({
                      ...filterData,
                      selectedMonth: item?.month,
                      filterType: false,
                    })
                  : setStatus(item?.status);
                if (item.text !== "Industry Type") {
                  if (item?.text === "All Gigs") {
                    setchekedItems([]);
                  }
                  handleClose();
                }

                if (item?.filter === "today") {
                  setFilter({ ...filterData, filterStatus: "today" });
                }
                if (item?.filter === "monthly") {
                  setFilter({
                    ...filterData,
                    filterStatus: "monthly",
                    selectedYear: currentYear,
                    selectedMonth: "01",
                  });
                }
                if (item?.filter === "yearly") {
                  setFilter({
                    ...filterData,
                    filterStatus: "yearly",
                    selectedYear: currentYear,
                  });
                }
              }}
              disableRipple
            >
              {item.text === "Industry Type" ? (
                <IndustryTypeList
                  Item={item}
                  checkedItems={checkedItems}
                  setchekedItems={setchekedItems}
                />
              ) : (
                <Typography color={"black"} textTransform={"capitalize"}>
                  {" "}
                  {item.text}
                </Typography>
              )}
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
}
