import Layout from "../../../LayoutModule/Components/Layout";
import {
  Box,

  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchInput from "../../../../Component/SearchInput/SearchInput";
import BackButton from "../../../../Component/BackButton/BackButton";
const style = {
  color: "common.black",
  fontWeight: 600,
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
};
const tablebodyStyle = {
  color: "text.grey",
  fontWeight: 500,
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
};
const purchaseHistory = [
  {
    SubscriptionName: "Subscription1",
    SubscriptionStartDate: "22-Jan-2023",
    SubscriptionExpiryDate: "22-Jan-2024",
  },

];
function SupplierPurchaseHistory() {
  return (
    <Layout>
      <Box position="relative">
        <BackButton path={-1} />
        <Box width={'30%'} sx={{ float: "right" }} ><SearchInput placeholder="Search " /></Box>
        <Typography align='center' fontSize={25} fontWeight={600} color={"common.black"} >Supplier Purchase History</Typography>

        <Box py={2}>
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: "#D9D9D9" }}>
                    {["S.no", "Subscription Name", "Subscription Start Date", "Subscription Expiry Date"].map((item: string) => <TableCell key={item} align="center" sx={style}>
                      {item}
                    </TableCell>)}

                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchaseHistory?.map((item: any, index: number) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" width="5%" sx={tablebodyStyle}>
                        {index + 1}
                      </TableCell>

                      <TableCell align="center" sx={tablebodyStyle}>
                        {item?.SubscriptionName}
                      </TableCell>
                      <TableCell align="center" sx={tablebodyStyle}>
                        {item?.SubscriptionStartDate}
                      </TableCell>
                      <TableCell align="center" sx={tablebodyStyle}>
                        {item?.SubscriptionExpiryDate}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              // count={totalPages}
              //   onChange={handleChange}
              //   page={page}
              shape="rounded"
              sx={{
                float: "right",
                boxShadow: "none",
                my: 2,
                color: "text.lightgrey",
              }}
            />
          </Paper>
        </Box>
      </Box>
    </Layout>
  );
}

export default SupplierPurchaseHistory;
