import { Box, IconButton } from "@mui/material";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { queryClient } from "../../../../Utils/ReactQueryConfig";
import useSupplierMutation from "../../Hooks/useSupplierMutation";
import SnackbarHandler from "../../../../Utils/SnackbarHandler";
import ModalFormInput from "../../../../Component/ModalFormInput/ModalFormInput";
import ModalFormButton from "../../../../Component/ModalFormButton/ModalFormButton";

function AddSupplierForm({ handleClose }: any) {
  const { control, handleSubmit, reset } = useForm();

  const addSupplierMutation = useSupplierMutation();

  const addSupplier = handleSubmit((values) => {
    addSupplierMutation.mutate(
      {
        email: values?.email,
        fullName: values?.fullName,
        status: true,
        isApproved: true,
      },
      {
        onSuccess: (response) => {
          if (response) {
            reset({});
            queryClient.refetchQueries();
            handleClose();
            SnackbarHandler.successToast(response?.data?.message);
          }
        },
      }
    );
  });

  return (
    <>
      <Box
        p={5}
        width={"25%"}
        bgcolor={"primary.light"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        borderRadius={"15px"}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 5, top: 5 }}
        >
          <CloseIcon />
        </IconButton>

        <form onSubmit={addSupplier} style={{ width: "90%" }}>
          <ModalFormInput
            name="email"
            control={control}
            placeholder="Email"
            label=" Supplier's Email"
            type="text"
          />
          <ModalFormInput
            name="fullName"
            control={control}
            placeholder="Full Name"
            label=" Supplier's FullName"
            type="text"
          />
          <ModalFormButton reset={reset} />
        </form>
      </Box>
    </>
  );
}

export default AddSupplierForm;
