export const getRequiredRules = (label: string, requiredMessage?: string) => {
  return {
    validate: (value: string | number) =>
      (typeof value === "string" ? (value || "")?.trim() : value)
        ? true
        : requiredMessage
        ? requiredMessage
        : `${label} is required`,
  };
};
