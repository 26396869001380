import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import ClientGigsTable from "../ClientGigTable/ClientGigTable";
import ProfileCard from "../../../../Component/ProfileCard/ProfileCard";
import BackButton from "../../../../Component/BackButton/BackButton";
import useClientGigsDeatilsQuery from "../../Hooks/useClientGigsDeatilsQuery";
import { useDebounce } from "use-debounce";
import { useParams } from "react-router-dom";
import useClientDetailsQuery from "../../Hooks/useClientDetailsQuery";
import Layout from "../../../LayoutModule/Components/Layout";
const ClientDeatils = () => {
  const [showmore, setShowmore] = useState(false);
  const [tab, setTab] = useState("Gig");
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [search] = useDebounce(searchQuery, 1000);

  const { id: clientId } = useParams();
  const { data: clientData } = useClientDetailsQuery(clientId);

  const clientdetails = clientData?.data?.data;

  const { data } = useClientGigsDeatilsQuery(
    clientdetails?._id,
    page,
    clientdetails?.userType,
    search
  );
  const clientGigQuery = useClientGigsDeatilsQuery(
    clientdetails?._id,
    page,
    clientdetails?.userType,
    search
  );
  const list = data?.data?.data?.list ?? [];
  const totalPage: any = data?.data?.data?.count / 10;

  const { city, state, zipCode } = clientdetails?.address ?? {};
  const text = clientdetails?.aboutMe
    ? clientdetails?.aboutMe
    : "Not Available";
  // console.log("fbljw,efgjbvsvgc;lesjkdvb;yuildsglbde", clientdetails?.industryType?.length)
  return (
    <Layout>
      <Paper>
        <Box position="relative">
          <BackButton path={-1} />
          <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Typography
                variant="h4"
                sx={{ color: "text.grey", float: "right" }}
                fontWeight={600}
                fontSize={32}
                letterSpacing={1}
                p={3}
                textTransform={"capitalize"}
              >
                {clientdetails?.fullName ?? "Not Available"}
              </Typography>
              <Box display="flex" width="100%">
                <Box
                  width={"40%"}
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <ProfileCard
                    heading="city"
                    content={city ? city : "Not Available"}
                  />{" "}
                  <ProfileCard
                    heading="state"
                    content={state ? state : "Not Available"}
                  />
                  <ProfileCard
                    heading="zipcode"
                    content={zipCode ? zipCode : "Not Available"}
                  />
                  <ProfileCard heading="star rating" content="Not Available" />
                  <ProfileCard
                    heading="Service Type"
                    content={
                      clientdetails?.industryType?.length !== 0
                        ? clientdetails?.industryType?.join(" , ")
                        : "Not Available"
                    }
                  />
                </Box>

                <Box flex={1}>
                  <Typography
                    fontSize={20}
                    fontWeight={600}
                    // px={2}
                    py={0.5}
                    color={"text.grey"}
                  >
                    Description
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    //   textAlign="right"
                    sx={{ color: "text.lightgrey" }}
                    textTransform="capitalize"
                  >
                    {showmore ? text : text.substring(0, 500)}
                    {text.length > 500 ? (
                      <Button
                        sx={{
                          color: "text.primary",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          setShowmore(!showmore);
                        }}
                      >
                        {showmore ? "showless " : "showmore"}
                      </Button>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  justifyContent={"space-evenly"}
                  sx={{ color: "text.grey" }}
                >
                  <div
                    onClick={() => {
                      setTab("Gig");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      fontWeight={600}
                      px={2}
                      py={0.5}
                      borderBottom={tab === "Gig" ? 3 : 0}
                      borderColor={"text.grey"}
                    >
                      Gig
                    </Typography>
                  </div>
                  <div
                    onClick={() => {
                      setTab("Reviews");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      fontWeight={600}
                      px={2}
                      py={0.5}
                      borderBottom={tab === "Reviews" ? 3 : 0}
                      borderColor={"text.grey"}
                    >
                      Reviews
                    </Typography>
                  </div>
                </Stack>
                <Divider />
                {tab === "Reviews" ? (
                  <Typography
                    variant="h5"
                    py={12}
                    px={10}
                    color={"black"}
                    textAlign={"center"}
                    fontWeight={600}
                  >
                    {" "}
                    Under Development{" "}
                  </Typography>
                ) : (
                  <ClientGigsTable
                    page={page}
                    totalPages={Math.floor(totalPage) + 1}
                    setPage={setPage}
                    gigs={list}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    clientGigQuery={clientGigQuery}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Layout>
  );
};

export default ClientDeatils;
