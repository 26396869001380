import { BorderColor } from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CommonColors {
    grey: string;
    lightgrey: string;
  }

  export interface TypeText {
    grey: string;
    lightgrey: string;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#3D65FC",

      light: "#fff",
    },
    secondary: {
      main: "#3D65FC",
      light: "#f2f6ff",
    },
    background: {
      default: "#f6f7fa",
    },
    text: {
      primary: "#5E17EB",
      secondary: "#02989D",
      grey: "#404145",
      lightgrey: "#62646A",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
  },
  // "fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline":{BorderColor },
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          overflow: "visible",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: "none",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
});

export default theme;
// fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline
