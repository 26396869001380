import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "../../../Utils/Helper";

export const GET_INDUSTRY_LIST = (
  page: number,
  searchQuery: string,
  limit?: number
) => ["industry", page, searchQuery, limit];

const IndustryDetails = async (
  page: number,
  searchQuery: string,
  limit?: number
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: `/admin/industry?page=${page}&search=${searchQuery}&limit=10`,
    method: "GET",
  });
};

function useIndustryQuery(page: number, searchQuery: string, limit?: number) {
  return useQuery(GET_INDUSTRY_LIST(page, searchQuery, limit), () =>
    IndustryDetails(page, searchQuery, limit)
  );
}

export default useIndustryQuery;
