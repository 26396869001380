import React from "react";
import Layout from "../../../LayoutModule/Components/Layout";
import SupplierGigsTable from "../SupplierGigsTable/SupplierGigsTable";

function SupplierGigs() {
  return (
    <Layout>
      <SupplierGigsTable />
    </Layout>
  );
}

export default SupplierGigs;
