import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  IconButton,
  Button,
  Backdrop,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SUPPLIER_LIST_RESPONSE_TYPES, SUPPLIER_RESPONSE_LIST } from "../../Types/ResponseTypes";
import { useSearchParams } from "react-router-dom";
import useSupplierMutation from "../../Hooks/useSupplierMutation";
import { SUPPLIER_LIST_REQUEST_TYPE } from "../../Types/RequestTypes";
import SnackbarHandler from "../../../../Utils/SnackbarHandler";
import { AxiosResponse } from "axios";
import { UseQueryResult } from "react-query";
import TableSkeletonLoader from "../../../../Component/TableSkeletonloader/TableSkeletonLoader";


type SupplierRequestListProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  suppliers: any;
  totalPages: number | any;
  supplierQuery: UseQueryResult<AxiosResponse<SUPPLIER_LIST_RESPONSE_TYPES, any>>;
  currentPage: number | any
};
const style = {
  color: "common.black",
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
};
const tableBodyStyle = {
  color: "text.grey",
  fontWeight: 500,
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
};
function SupplierRequestTable(props: SupplierRequestListProps) {
  // let [searchParams, setSearchParams] = useSearchParams();
  const { page, setPage, totalPages, suppliers, currentPage, supplierQuery } = props;
  const supplierApprovalMutation = useSupplierMutation();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);

  };
  const [idProof, setIdproof] = useState({
    legalId: "",
    legalIdNo: ""
  })
  const [backdropOpen, setbackdropOpen] = useState(Boolean);
  // const handleOpen = () => {
  //   setbackdropOpen(true);

  // };
  const handleClose = () => {
    setbackdropOpen(false);
    setIdproof({ legalId: "", legalIdNo: "" })
  };

  const supplierApproval = ({
    email,
    fullName,
    status,
    isApproved,
    supplierId,
  }: SUPPLIER_LIST_REQUEST_TYPE) => {
    supplierApprovalMutation.mutate(
      {
        email: email,
        fullName: fullName,
        status: status,
        supplierId: supplierId,
        isApproved: isApproved,
      },
      {
        onSuccess: (response: any) => {
          SnackbarHandler.successToast(response?.data?.message)
        }
      }

    );
  };
  return (
    <Box py={2}>
      {supplierQuery?.isError ?
        <Typography variant='h5' p={10} color={"black"} textAlign={"center"} fontWeight={600}> No Supplier Found! </Typography>
        : <>
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: "#D9D9D9" }}>
                    {["S.no",
                      "Name",
                      //  "Agency/Company", 
                      "Email Id",
                      // "Phone Number", 
                      // "Address",
                      //  "City", 
                      //  "Country", 
                      //  "Id", 
                      //  "Profile",
                      "Approval"].map((item: string) => <TableCell align="center" sx={style} key={item} >
                        {item}
                      </TableCell>)}

                  </TableRow>
                </TableHead>
                <TableBody>
                  {supplierQuery?.isLoading ? <TableSkeletonLoader rows={10} column={4} /> : <>
                    {suppliers?.map((item: SUPPLIER_RESPONSE_LIST, index: number) => (
                      <TableRow
                        key={item._id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell
                          align="center"
                          width="5%"
                          sx={tableBodyStyle}
                        >
                          {10 * (currentPage - 1) + (index + 1)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                        >
                          {item.fullName ? item.fullName : "Not Available"}
                        </TableCell>
                        {/* <TableCell align="center" sx={tableBodyStyle}>
                          {item?.companyName ?? "Not Available"}
                        </TableCell> */}
                        <TableCell align="center" sx={tableBodyStyle}>
                          {item?.email ? item?.email : "Not Available"}
                        </TableCell>
                        {/* <TableCell align="center" sx={tableBodyStyle}>
                          {item?.countryCode}{item?.phoneNumber ?? "Not Available"}
                        </TableCell>

                        <TableCell align="center" sx={tableBodyStyle}>
                          {item?.address?.address ?item?.address?.address:"Not Available"}
                        </TableCell>
                        <TableCell align="center" sx={tableBodyStyle}>
                          {item?.address?.city ?item?.address?.city : "Not Available"}
                        </TableCell>
                        <TableCell align="center" sx={tableBodyStyle}>
                          {item?.address?.country ?item?.address?.country : "Not Available"}
                        </TableCell>
                        <TableCell align="center" sx={tableBodyStyle}>

                          <ToolTip title="Id Proof" placement="bottom" arrow>
                            <div onClick={() => { setbackdropOpen(true); setIdproof({ legalId: item?.legalId, legalIdNo: item?.legalIdNo }) }} style={{ cursor: "pointer" }}>
                              <InsertDriveFileIcon />
                            </div>
                          </ToolTip>
                        </TableCell>
                        <TableCell align="center">
                          <ToolTip title="Profile" placement='bottom' arrow >
                            <IconButton
                              onClick={() => {
                                setSearchParams({ supplierId: item?._id });
                              }}
                            >
                              <Visibility />
                            </IconButton>
                          </ToolTip>
                        </TableCell> */}
                        <TableCell align="center">
                          <Button
                            color="success"
                            variant="contained"
                            onClick={() =>
                              supplierApproval({
                                email: item?.email,
                                fullName: item?.fullName,
                                status: true,
                                supplierId: item?._id,
                                isApproved: true,
                              })
                            }
                          >
                            Approve
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>}
                </TableBody>
              </Table>
            </TableContainer>

            <Pagination
              count={totalPages}
              onChange={handleChange}
              page={page}
              shape="rounded"
              sx={{
                float: "right",
                boxShadow: "none",
                my: 2,
                color: "text.lightgrey",
              }}
            />
          </Paper>
          <Backdrop open={backdropOpen} sx={{ zIndex: 3 }}>
            <Box
              width={"30%"}
              bgcolor={"white"}
              position="relative"
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              zIndex={8}
            >

              {idProof?.legalIdNo && idProof?.legalId ? <Box p={5} zIndex={999}>
                <img src={idProof?.legalId} alt="not available" width={"250px"} height={"250px"} style={{ zIndex: 11 }} />
                <Typography pt={3} align="center" variant="h6" sx={{ color: "text.grey" }}> LegalId No : {idProof?.legalIdNo ?? "00000000"}
                </Typography>
              </Box> :
                <><Typography p={10} variant="h3" sx={{ color: "text.grey" }}>Not Available</Typography> </>}
              <IconButton
                onClick={handleClose}
                sx={{ position: "absolute", right: 5, top: 5 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Backdrop>
        </>}
    </Box>
  );
}
export default SupplierRequestTable;
