import { Box, Button, Typography } from "@mui/material";
import Layout from "../../../LayoutModule/Components/Layout";
import BackButton from "../../../../Component/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import useClientGigDescriptionQuery from "../../../ClientModule/Hooks/useClientGigDescriptionQuery";
import { CLIENT_GIG_DESCRIPTION_DATA } from "../../../ClientModule/Types/ResponseTypes";

function SupplierGigDetails() {
  const navigate = useNavigate();
  const { id: gigId } = useParams();
  const data = useClientGigDescriptionQuery(gigId);
  const gigDetail: CLIENT_GIG_DESCRIPTION_DATA | undefined =
    data?.data?.data?.data;
  // console.log("supplieerIddddd", gigDetail?.supplierId)
  const toDataURL = async (url: any) => {
    return await fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return window.URL.createObjectURL(blob);
      });
  };
  const downloadAttachment = async (url: any) => {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = url.replace(/^.*[\\/]/, "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const downloadAllAttachments = () => {
    if (gigDetail?.attachments && gigDetail?.attachments.length > 0) {
      gigDetail?.attachments.forEach((attachment) => {
        // console.log("wuifgdqleydyeldiyqweufld", gigDetail?.attachments)
        downloadAttachment(attachment);
      });
    } else {
      console.warn("No attachments found.");
    }
  };
  return (
    <Layout>
      <Box position="relative">
        <BackButton path={-1} />
        <Box
          display="flex"
          alignItems="center"
          width={"100%"}
          justifyContent="space-between"
        >
          <Typography variant="h4" fontWeight={600} py={2} color={"black"}>
            {gigDetail?.title}
          </Typography>
          <Button
            variant="contained"
            size="medium"
            sx={{ bgcolor: "text.primary", color: "white" }}
            onClick={() =>
              navigate(
                `/supplier-management/supplier-proposal/${gigDetail?._id}/${gigDetail?.supplierId}`
              )
            }
          >
            View Proposals
          </Button>
        </Box>
        <Box
          p={1}
          mt={3}
          display="flex"
          alignItems="center"
          width={"100%"}
          justifyContent="space-between"
        >
          <Typography variant="h5" sx={{ color: "text.grey" }} fontWeight={500}>
            More About This Gigs{" "}
          </Typography>
          <Button
            variant="outlined"
            size="medium"
            onClick={downloadAllAttachments}
          >
            Download Attachment
          </Button>
        </Box>
        <Typography sx={{ color: "text.grey" }} fontSize={14} p={1}>
          {gigDetail?.description}{" "}
        </Typography>
      </Box>
    </Layout>
  );
}

export default SupplierGigDetails;
