import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "../../../Utils/Helper";
import { SUPPLIER_DETAILS_TYPES } from "../Types/ResponseTypes";

export const GET_SUPPLIER_DETAILS = (SupplierId: string | any) => [
  "supplierdetails",
  SupplierId,
];
const SupplierDetails = async (
  SupplierId: string | null
): Promise<AxiosResponse<SUPPLIER_DETAILS_TYPES>> => {
  return fetcher({
    url: `/admin/supplier/${SupplierId}`,
    method: "GET",
  });
};

function useSupplierDetailsQuery(SupplierId: string | any) {
  return useQuery(GET_SUPPLIER_DETAILS(SupplierId), () =>
    SupplierDetails(SupplierId)
  );
}

export default useSupplierDetailsQuery;
