import { Visibility } from "@mui/icons-material";
import {
  Box, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Avatar, IconButton, Switch, Typography,
} from "@mui/material";
import React from "react";
import { SUPPLIER_LIST_RESPONSE_TYPES, SUPPLIER_RESPONSE_LIST } from "../../Types/ResponseTypes";
import { useNavigate } from "react-router-dom";
import useSupplierMutation from "../../Hooks/useSupplierMutation";
import SnackbarHandler from "../../../../Utils/SnackbarHandler";
import ToolTip from "../../../../Component/Tooltip/Tooltip";
import { AxiosResponse } from "axios";
import { UseQueryResult } from "react-query";
import TableSkeletonLoader from "../../../../Component/TableSkeletonloader/TableSkeletonLoader";
type SupplierListProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  supplierQuery: UseQueryResult<AxiosResponse<SUPPLIER_LIST_RESPONSE_TYPES, any>>,
  suppliers: any;
  totalPages: number | any;
  currentPage: number | any;
};
const style = {
  color: "common.black",
  fontWeight: 600,
  textTransform: "capitalize",
  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
};
const tablebodyStyle = {
  color: "text.grey",
  fontWeight: 500,

  fontSize: {
    lg: 16,
    md: 14,
    sm: 12,
    xs: 10,
  },
};
function SupplierListTable(props: SupplierListProps) {
  // const [searchParams, setSearchParams] = useSearchParams();
  const supplierStatusChange = useSupplierMutation();
  const { page, setPage, totalPages, suppliers, currentPage, supplierQuery } = props;
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return (
    <Box py={2}>
      {supplierQuery?.isError ?
        <Typography variant='h5' p={10} color={"black"} textAlign={"center"} fontWeight={600}> No Supplier Found! </Typography>
        :
        <Paper>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ bgcolor: "#D9D9D9" }}>
                  {[
                    "S.no",
                    "Profile Pic",
                    "Name",
                    "Company/Agency",
                    "Email",
                    "Phone Number",
                    "Industry type",
                    "Reviews",
                    "Purchase History",
                    "Gigs",
                    "View Details",
                    "Status",
                  ].map((item: string) => (
                    <TableCell
                      color="text.secondary"
                      align="center"
                      sx={style}
                      key={item}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {supplierQuery?.isLoading ?
                  <TableSkeletonLoader rows={10} column={12} />
                  :
                  <>

                    {suppliers?.map((item: SUPPLIER_RESPONSE_LIST, index: number) => (
                      <TableRow
                        key={item._id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="center" width="5%" sx={tablebodyStyle}>
                          {10 * (currentPage - 1) + (index + 1)}
                        </TableCell>
                        <TableCell align="center" sx={tablebodyStyle}>
                          <Avatar sx={{ ml: 1 }} src={item?.companyLogo} />
                        </TableCell>
                        <TableCell align="center" sx={tablebodyStyle}>
                          {item?.fullName ? item?.fullName : "Not Available"}
                        </TableCell>
                        <TableCell align="center" sx={tablebodyStyle}>
                          {item?.companyName ? item?.companyName : "Not Available"}
                        </TableCell>
                        <TableCell align="center" sx={tablebodyStyle}>
                          {item?.email ? item?.email : "Not Available"}
                        </TableCell>
                        <TableCell align="center" sx={tablebodyStyle}>
                          {item?.countryCode}
                          {item?.phoneNumber ? item?.phoneNumber : "Not Available"}
                        </TableCell>
                        <TableCell align="center" sx={tablebodyStyle}>
                          {item?.industryType?.length !== 0 ? <Typography sx={tablebodyStyle} >{item?.industryType?.join(",")}</Typography> : "Not Available"}
                        </TableCell>
                        <TableCell align="center">
                          <ToolTip title="Reviews" placement="bottom" arrow>
                            <IconButton
                              onClick={() => {
                                navigate(`/supplier-management`);
                              }}
                            >
                              <Visibility />
                            </IconButton>
                          </ToolTip>
                        </TableCell>
                        <TableCell align="center">
                          <ToolTip title="Purchase History" placement="bottom" arrow>
                            <IconButton
                              onClick={() => {
                                navigate(`/supplier-management/purchase-history`);
                              }}
                            >
                              <Visibility />
                            </IconButton>
                          </ToolTip>
                        </TableCell>
                        <TableCell align="center">
                          <ToolTip title="Gigs" placement="bottom" arrow>
                            <IconButton
                              onClick={() => {
                                navigate(
                                  `/supplier-management/gigs?supplierId=${item._id} `
                                );
                              }}
                            >
                              <Visibility />
                            </IconButton>
                          </ToolTip>
                        </TableCell>
                        <TableCell align="center">
                          <ToolTip title="View Details" placement="bottom" arrow>
                            <IconButton
                              onClick={() => {
                                navigate(`/supplier-management/supplier-profile/${item?._id}`)
                              }}
                            >
                              <Visibility />
                            </IconButton>
                          </ToolTip>
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            color="success"
                            onChange={() => {
                              supplierStatusChange.mutate(
                                {
                                  email: item?.email,
                                  fullName: item?.fullName,
                                  supplierId: item._id,
                                  status: !item.status,
                                },
                                {
                                  onSuccess: (response: any) => {
                                    SnackbarHandler.successToast(
                                      response?.data?.message
                                    );
                                  },
                                }
                              );
                            }}
                            checked={item.status}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            onChange={handleChange}
            page={page}
            shape="rounded"
            sx={{
              float: "right",
              boxShadow: "none",
              my: 2,
              color: "text.lightgrey",
            }}
          />
        </Paper>}
    </Box>
  );
}
export default SupplierListTable;
